.font(@font_name, @file_name, @weight, @style) {
  @font-face {
    font-family: @font_name;
    src: url("/fonts/@{file_name}.woff") format("woff"), url("/fonts/@{file_name}.woff2") format("woff2");
    font-weight: @weight;
    font-style: @style;
    font-display: swap;
  }
}


.font("Roboto", "Roboto-Regular", 400, normal);
.font("Roboto", "Roboto-Medium", 500, normal);
.font("Roboto", "Roboto-Bold", 700, normal);
.font("Roboto", "Roboto-Black", 900, normal);
.font("dripicons-v2", "dripicons-v2", normal, normal);