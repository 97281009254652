
.slider-comments-type {
  position: relative;
  padding-bottom: calc(@gap*4);

  .slides-wrapper {
    position: relative;
    min-height: 260px;
  }

  &__item {
    display: flex;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    position: absolute;
    top: 50%;
    transition: all 0.5s ease-in-out;

    &.active-slide {
      z-index: 8;
      opacity: 1;
      left: 50%;
      transform: translate(-50%, -50%) scale(1);
    }

    &.prev-slide {
      z-index: 7;
      opacity: 0.6;
      left: 0;
      transform: translate(-10%, -50%) scale(0.9);
    }

    &.next-slide {
      right: 0;
      z-index: 7;
      opacity: 0.6;
      transform: translate(10%, -50%) scale(0.9);
    }

    &:not(.prev-slide):not(.next-slide):not(.active-slide) {
      opacity: 0;
      z-index: 6;
      left: 50%;
      transform: translate(-50%, -50%) scale(0.8);
    }
  }

  .slider-pages {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    display: flex;
    min-height: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    gap: @gap;

    > *:nth-child(2){
      opacity: 0.5;
    }

  }

  .slide-prev-btn, .slide-next-btn {
    position: absolute;
    display: flex;
    top: auto;
    bottom: 0;
    width: 40px;
    height: 40px;
    z-index: 8;
    background: rgba(@color-b4, 1) !important;
    border-radius: 100%;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    backdrop-filter: blur(4px);
    border: none;
    outline: none;

    svg {
      width: 24px;
      height: 24px;
      fill: @color-w;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background: @color-hover !important;
        box-shadow: @primary-btn-hover;
      }
    }
  }

  .slide-prev-btn {
    left: calc(50% - 90px);

    &::before {
      content: '';
      left: -1px;
    }
  }

  .slide-next-btn {
    right: calc(50% - 90px);

    &::before {
      content: '';
      top: 1px;
      right: -1px;
    }
  }

}


@keyframes active-to-next {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    opacity: 0.9;
    transform: translate(20%, -50%) scale(1.1);
  }
  100% {
    opacity: 0.8;
    transform: translate(0, -50%) scale(0.9);
  }
}

@keyframes active-to-prev {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    opacity: 0.9;
    transform: translate(-120%, -50%) scale(1.1);
  }
  100% {
    opacity: 0.8;
    transform: translate(-100%, -50%) scale(0.9);
  }
}

@keyframes prev-to-active {
  0% {
    opacity: 0.8;
    transform: translate(-100%, -50%) scale(0.9);
  }
  50% {
    opacity: 0.9;
    transform: translate(-120%, -50%) scale(1.1);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes next-to-active {
  0% {
    opacity: 0.8;
    transform: translate(0, -50%) scale(0.9);
  }
  50% {
    opacity: 0.9;
    transform: translate(20%, -50%) scale(1.1);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}