// Video
.in-video-wrap {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  border-radius: @br;
  padding-bottom: 56%;
  background-color: @color-w;
  box-shadow: @shadow-light;
  margin-bottom: calc(@gap*4);

  .video__media {
    position: absolute;
    top: -1%;
    left: 0;
    object-fit: cover;
    width: 102%;
    height: 102%;
    border: none;
  }

  .video__button {
    position: absolute;
    top: 50%;
    left: 50%;
    border: none;
    transform: translate(-50%, -50%);
    //display: flex;
    padding: 0;
    background: transparent;
    align-items: center;
    align-content: center;
    justify-content: center;
    outline: none;
    opacity: .5;

    svg {
      width: 100px;
      height: 100px;
    }

    .video__button-icon {
      fill: @color-w;
    }

    @media only screen and (max-width: 740px),
    only screen and (max-device-width: 740px) {
      width: 60px;
      height: 60px;
      opacity: 1;
      box-shadow: @shadow-light;
    }

  }

  &:hover .video__button,
  .video__button:focus .video__button-shape {
    opacity: 1;
  }

  &.video--enabled .video__button {
    display: flex;
  }

  &.video--enabled {
    cursor: pointer;
  }

  @media only screen and (max-width: 740px),
  only screen and (max-device-width: 740px) {
    border-radius: @br;
  }
}

