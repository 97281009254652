//============================================================================
//+++++++++++++++++++++++++++++++++ HEADER +++++++++++++++++++++++++++++++++++
//============================================================================

.desktop-menu-component{
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  z-index: 10;
  width: calc(100% + 2px);
  max-width: @width-max-limit;
  height: auto;
  top: 0;
  left: 50%;
  transform: translateX(calc(-50% - 1px));
  background: none;

  .menu-burger {
    display: none;
  }

  .resolution-wrap {
    display: flex;
    max-width: none;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    position: relative;
    .anim_on(@anim);

  }

  &__main {
    //background: rgba(@color-w, .9);
    //backdrop-filter: blur(13px);
    height: @header-h;
    max-height: @header-h;
    //border-bottom: 1px solid @color-b5;

    .logo {
      .anim_on(@anim);
      position: relative;
      margin-right: calc(@gap * 2);

      a {
        display: inline-flex;
        align-content: center;
        align-items: center;
        justify-content: flex-start;
        .anim_on(@anim);

        svg, img {
          width: 131px;
          height: calc(131px / 2.729);
          .anim_on(@anim);
        }
      }
    }

    &-tel {
      display: flex;
      justify-content: flex-start; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
      margin-right: calc(@gap * 2);
      position: relative;

      a {
        text-decoration: none;
        color: @color-b1;
      }

      .more-icon {
        margin-left: @gap / 2;
        position: relative;
        z-index: 2;

        svg {
          width: 16px;
          height: 16px;
          fill: @color-b3;
          .anim_on(@anim);
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover{
            cursor: pointer;

            svg {
              transform: scale(1.2);
              fill: @color-hover;
            }
          }
        }
      }
    }

    &-soc-networks {
      display: flex;
      gap: 4px;

      a {
        text-decoration: none;
        display: flex;

        svg {
          width: 24px;
          height: 24px;
          fill: @color-b3;
          .anim_on(@anim);
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover{
            svg {
              transform: scale(1.1);
              fill: @color-hover;
            }
          }
        }
      }

    }

    &__nav {
      display: flex;
      margin-left: auto;
      gap: calc(@gap * 3);

      .menu-list {
        display: flex;
        gap: calc(@gap * 3);

        &__item {
          position: relative;
          color: @color-b1;
          text-decoration: none;
          font: @fw-reg @header-menu/110% @font-primary;
          text-transform: unset;
          display: flex;
          padding: calc(@gap * 2) 0;
          justify-content: center; // align X
          align-items: center; // align Y
          align-content: center; // align Y ios
          min-height: @header-h !important;

          &::after {
            content: '';
            position: absolute;
            transform: translateX(-50%);
            left: 50%;
            .anim_on(@anim);
            top: 0;
            width: 1%;
            height: 3px;
            background: @color-primary;
            opacity: 0;
          }

          &.active {
            &::after {
              content: '';
              width: 100%;
              opacity: 1;
            }
          }

          @media (hover: hover) and (pointer: fine) {
            &:not(.active):hover {
              color: @color-hover;
            }
          }
        }
      }

      .other-tools {
        display: flex;
        gap: 16px;
        justify-content: center; // align X
        align-items: center; // align Y
        align-content: center; // align Y ios
        order: 99;

        svg {
          .anim_on(@anim);
        }

        .language {
          display: flex;
          width: 24px;
          height: 24px;
          min-width: 24px;
          justify-content: center; // align X
          align-items: center; // align Y
          align-content: center; // align Y ios
          position: relative;

          &__button {
            display: inline-flex;

            @media (hover: hover) and (pointer: fine) {
              &:hover {
                svg {
                  transform: scale(1.1);
                  fill: @color-hover;
                  cursor: pointer;
                }
              }
            }

            svg {
              .anim_on(@anim);
              width: 24px;
              height: 24px;
              fill: @color-b2;
            }
          }

          .lang-hint{
            display: none;
          }
        }

        .user-block {
          display: flex;
          width: 24px;
          height: 24px;
          min-width: 24px;
          justify-content: center; // align X
          align-items: center; // align Y
          align-content: center; // align Y ios
          position: relative;

          &__button {
            display: inline-flex;

            @media (hover: hover) and (pointer: fine) {
              &:hover {
                svg {
                  transform: scale(1.1);
                  fill: @color-hover;
                  cursor: pointer;
                }
              }
            }

            svg {
              .anim_on(@anim);
              width: 24px;
              height: 24px;
              fill: @color-b2;
            }
          }


        }
      }
    }
  }

  // SMALL DESKTOP
  &.header__small {
    background: rgba(@color-w, 0.9);
    backdrop-filter: blur(4px);
    border-bottom: 1px solid @color-b5;

    .logo {
      a {
        svg, img {
          width: 110px;
          height: calc(110px / 2.729);
          .anim_on(@anim);
        }
      }
    }

    .header__main {
      height: @header-small-h;

      &__nav {
        .menu-list {
          display: flex;

          &__item {
            font: @fw-reg @header-menu/110% @font-primary;
            min-height: @header-small-h !important;
          }
        }
      }
    }
  }

  .dropdown-menu{

    &__box {
      position: absolute;
      display: flex !important;
      top: 32px;
      right: -@gap;
      box-shadow: @shadow-light;
      opacity: 0;
      pointer-events: none;
      transform: scaleY(0.8);
      transition: all 0.1s ease-in-out;

      &::after {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 1px;
        transform: rotate(-45deg);
        border-top: 1px solid @color-b6;
        border-right: 1px solid @color-b6;
        pointer-events: none;
        background: @color-w;
        position: absolute;
        top: -4px;
        right: @gap*2;
      }

      &__wrap {
        display: flex;
        border: 1px solid @color-b6;
        background: @color-w;
        flex-direction: column;
        border-radius: @br;
        padding: calc(@gap / 2);

        > * {
          padding: @gap @gap*2;
          color: @color-b1;
          text-decoration: none;
          white-space: nowrap;

          &.active, &.profile-name {
            font-weight: @fw-bold !important;
          }

          &:not(:last-child) {
            border-bottom: 1px solid @color-b6;
          }

          @media (hover: hover) and (pointer: fine) {
            &:not(.active):not(.profile-name):hover {
              color: @color-hover;
              cursor: pointer;
            }
          }
        }
      }

    }

    &.open{
      .dropdown-menu__btn{
        svg{
          fill: @color-primary;
        }
      }
      .dropdown-menu__box{
        opacity: 1;
        pointer-events: all;
        transform: scaleY(1);
      }
    }

  }
}

.mobile-menu-component{
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  z-index: 10;
  width: calc(100% + 2px);
  max-width: @width-max-limit;
  height: auto;
  top: 0;
  left: 50%;
  transform: translateX(calc(-50% - 1px));
  background: none;

  .menu-burger {
    display: flex;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 10px;
    top: 50%;
    z-index: 3;
    transform: translateY(calc(-50% - 2px));

    &::before,
    i,
    &::after {
      content: '';
      display: block;
      width: 60%;
      height: 2px;
      background-color: @color-b2;
      position: absolute;
      transform-origin: 0 50%;
      left: 50%;
      transform: translateX(-50%);
      .anim_on(@anim);
    }

    &::before {
      top: 30%;
    }

    i {
      top: 50%;
    }

    &::after {
      top: 70%;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        cursor: pointer;

        &::before,
        i,
        &::after {
          background-color: @color-primary;
        }
      }
    }
  }

  .resolution-wrap {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    position: relative;
    max-width: none;
    .anim_on(@anim);
    padding-left: calc(8px* 2);
    padding-right: calc(8px* 2);
  }

  &__main {
    //background: rgba(@color-w, .9);
    //backdrop-filter: blur(13px);
    height: @header-small-h;
    max-height: @header-small-h;
    //border-bottom: 1px solid @color-b5;

    .logo {
      .anim_on(@anim);
      position: relative;
      margin-right: calc(@gap * 2);

      a {
        display: inline-flex;
        align-content: center;
        align-items: center;
        justify-content: flex-start;
        .anim_on(@anim);

        svg, img {
          width: 120px;
          height: calc(120px / 3.12);
          .anim_on(@anim);
        }
      }
    }

    &-tel {
      display: none;
      justify-content: flex-start; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
      margin-right: calc(@gap * 2);

      @media (min-width: 280px) and (max-width: 420px) {
        display: none !important;
      }

      a {
        text-decoration: none;
        color: @color-b1;
      }

      .more-icon {
        margin-left: @gap / 2;

        svg {
          width: 16px;
          height: 16px;
          fill: @color-b3;
        }
      }
    }

    &-soc-networks {
      display: flex;
      gap: 4px;

      @media (min-width: 280px) and (max-width: 520px) {
        display: none !important;
      }

      a {
        text-decoration: none;
        display: flex;

        svg {
          width: 24px;
          height: 24px;
          fill: @color-b3;
          .anim_on(@anim);
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover{
            svg {
              transform: scale(1.1);
              fill: @color-hover;
            }
          }
        }
      }

    }

    &__nav {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 420px;
      min-width: 300px;
      margin-left: auto;
      position: absolute;
      transform: translateX(100%);
      opacity: 0;
      visibility: hidden;
      background: rgba(@color-w, 1);
      backdrop-filter: blur(0);
      top: 0;
      right: 0;
      height: var(--app-height);
      .anim_on(@anim);
      box-shadow: @shadow-large;
      padding-top: @header-small-h;

      overflow: hidden;
      overflow-y: auto;

      .menu-list {
        display: flex;
        flex-direction: column;
        margin-bottom: @gap*2;

        &__item, a {
          position: relative;
          justify-content: center; // align X
          align-items: center; // align Y
          align-content: center; // align Y ios
          color: @color-b1;
          text-decoration: none;
          font: @fw-reg @header-menu/110% @font-primary;
          display: flex;
          padding: calc(@gap * 2);
          text-align: center;
          box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.03);

          &::after {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            .anim_on(@anim);
            width: 6px;
            height: 100%;
            background: @color-primary;
            border-radius:0 4px 4px 0;
            opacity: 0;
          }

          &.active {
            &::after {
              content: '';
              scale: 1;
              opacity: 1;
            }
          }
        }
      }

      .other-tools {
        display: flex;
        flex-direction: column;
        justify-content: center; // align X
        align-items: center; // align Y
        align-content: center; // align Y ios
        padding: 0 calc(@gap * 2);
        margin-bottom: calc(@gap * 3);
        flex-wrap: wrap;
        margin-top: auto;
        order: 99;

        svg {
          display: none;
          .anim_on(@anim);
        }

        .language{
          order: 99;
          display: flex;
          width: 100%;
          justify-content: center; // align X
          align-items: center; // align Y
          align-content: center; // align Y ios

          &__box{
            text-align: center;
            display: flex;
            flex-direction: column;
            padding: @gap*2 0;
            justify-content: center; // align X
            align-items: center; // align Y
            align-content: center; // align Y ios

            &__wrap{
              display: flex;
              justify-content: center; // align X
              align-items: center; // align Y
              align-content: center; // align Y ios
              flex-wrap: wrap;
              gap: 0 @gap*3;

              > *{
                flex: 0 0 auto;

                &.lang-hint{
                  color: @color-b3;
                }

                &.active{
                  font-weight: @fw-bold;
                }
                display: flex;
                padding: @gap 0;
                text-decoration: none;
                color: @color-b1;
              }
            }
          }
        }

        .user-block{
          display: flex;
          width: 100%;
          //border-bottom: 1px solid @color-b6;
          justify-content: center; // align X
          align-items: center; // align Y
          align-content: center; // align Y ios
          background: @color-b6;
          border-radius: @br;

          &__box{
            display: flex;
            flex-direction: column;
            padding: @gap*2 0;
            justify-content: center; // align X
            align-items: center; // align Y
            align-content: center; // align Y ios

            &__wrap{
              display: flex;
              flex-wrap: wrap;
              gap: 0 @gap*3;
              justify-content: center; // align X
              align-items: center; // align Y
              align-content: center; // align Y ios

              > *:not(a){
                display: flex;
                padding: @gap 0;
                color: @color-b1;

                &.profile-name{
                  display: flex;
                  justify-content: center; // align X
                  align-items: center; // align Y
                  align-content: center; // align Y ios
                  min-width: 100%;
                  margin-left: auto;
                  flex: 1 1 auto;
                  text-align: center;
                  font-size: @headname-5;
                  font-weight: @fw-bold;
                  text-decoration: none;
                }
              }
            }
          }
        }

      }

    }
  }

  &.menu-active {

    .menu-burger {

      &::before {
        top: 50%;
        left: 50%;
        transform: rotate(45deg) translate(-50%, calc(-50% + 1px));
        //width: 20px !important;
      }

      i {
        left: 70%;
        opacity: 0;
      }

      &::after {
        top: 50%;
        left: 50%;
        transform: rotate(-45deg) translate(-50%, calc(50% - 1px));
        //width: 20px !important;
      }

      &::before, i, &::after {
        width: 70% !important;
      }
    }

    .header__main__nav {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
    }
  }

  // SMALL MOBILE
  &.header__small {
    background: rgba(@color-w, 0.9);
    backdrop-filter: blur(4px);
    border-bottom: 1px solid @color-b5;

    .logo {
      a {
        svg, img {
          width: 110px;
          height: calc(110px / 2.729);
          .anim_on(@anim);
        }
      }
    }

    .header__main {
      height: @header-small-h;
    }
  }
}

.header .logo {
  position: relative;

  .signature-under-logo {
    display: flex;
    position: absolute;
    pointer-events: none;
    font: @fw-bold 10px/100% @font-primary;
    color: @color-b2;
    z-index: 0;
    bottom: 7px;
    left: 40%;
    text-decoration: none;
  }

  svg {
    > * {
      fill: @color-primary;
    }
  }
}

// HEADER (big - small)
// ====================================

// PARKING HEADER - MENU HIDEN + SEARCH
body[data-pages-type='parking-page'] .header{
  @media (min-width: 960px) and (max-width: @width-max-limit) {
    .mobile-menu-component;
    &__main{
      height: @header-h;
      max-height: @header-h;
    }
  }

  @media (min-width: 280px) and (max-width: 959.98px) {
    .mobile-menu-component;
  }
}

// PARKING HEADER - MENU HIDEN + SEARCH
body[data-pages-type='parking-page'][data-page="main"] .header{
  @media (min-width: 960px) and (max-width: @width-max-limit) {
    background: none;

    &.header__small {
      background: rgba(@color-w, 0.9);
    }
  }

  @media (min-width: 280px) and (max-width: 959.98px) {
    background: none;
    &.header__small {
      background: rgba(@color-w, 0.9);
    }
  }
}


// MENU STANDARD
body:not([data-page='parking-sf-street-cleaning']):not([data-page='parking-san-francisco']):not([data-pages-type='parking-page'])
.header{

    @media (min-width: 960px) and (max-width: @width-max-limit) {
      .desktop-menu-component;
    }

    @media (min-width: 280px) and (max-width: 959.98px) {
      .mobile-menu-component;
    }
}

.menu-list.second-list{
  margin-left: @gap*2;
  margin-right: @gap*2;
  margin-bottom: @gap*2;
  background:  rgba(@color-b6, 0.8);
  border-radius: @br;

  a{
    font-size: 14px !important;
    color: rgba(@color-b1, 0.8) !important;

    &:last-child{
      border-bottom: none !important;
    }
  }
}

// якщо це не стосується Street cleaning - прибираємо пошук!

.header__main.with-search,
header.header.with-search{
  .header-search{
    display: block !important;
  }
}



// logo not interactive on main page
body[data-page='main'] .logo {
  a {
    //pointer-events: none !important;
  }
}

body[data-page='main'] .dynamic-padding,
body[data-page='examples-page'] .dynamic-padding,
body[data-page='parking'] .dynamic-padding,
body[data-page='dmv-services'] .dynamic-padding{
  @media (min-width: 960px) and (max-width: @width-max-limit) {
    padding-top: 0;
  }
  @media (min-width: 280px) and (max-width: 959.98px) {
    padding-top: 0;
  }
}

body:not([data-page=main]):not([data-page=examples-page]):not([data-page=parking]):not([data-page=dmv-services]) .dynamic-padding {
  @media (min-width: 960px) and (max-width: @width-max-limit) {
    padding-top: @header-h;
  }
  @media (min-width: 280px) and (max-width: 959.98px) {
    padding-top: @header-small-h;
  }
}


