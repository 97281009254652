//============================================================================
//++++++++++++++++++++++++++++++ POP-UP WINDOWS ++++++++++++++++++++++++++++++
//============================================================================
body::after {
  content: '';
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 90;
  background: rgba(@color-b1, .8);
  position: fixed;
  top: 0;
  left: 0;
  .anim_on(@anim);
  opacity: 0;
  visibility: hidden;
}

body.popup__opened {

  &::after {
    opacity: 1;
    visibility: visible;
  }

  .main-container {
    filter: blur(1px);
  }
}

.popup {
  position: fixed;
  .anim_on(@anim);
  z-index: 999;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(@color-b1, 0.8);
  overflow-y: auto;
  overflow-x: hidden;
  opacity: 0;
  visibility: hidden;
  backdrop-filter: blur(6px);

  &__container {
    width: 100%;
    min-height: 100%;
    padding: 30px 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__window {
      position: relative;
      width: 100%;
      max-width: @width-popup-limit;
      padding: calc(@gap * 3);
      background: @color-w;
      border-radius: @br;
      box-shadow: @shadow-large;

      .anim_on(@anim);
      transform: perspective(600) translateY(40%) rotateX(10deg);


      // POPUP CLOSE BTN
      .popup__close {
        text-decoration: none;
        justify-content: center; // align X
        align-items: center; // align Y
        align-content: center; // align Y ios


        .anim_on(@anim);
        width: 32px;
        height: 32px;
        position: absolute;
        top: -8px;
        right: -8px;
        z-index: 15;
        background: @color-primary;
        border-radius: @br;
        box-shadow: 0 3px 20px -1px rgba(0, 0, 0, 0.05);

        &:active {
          transform: translateY(1px);
        }

        &::before, &::after {
          content: '';
          display: flex;
          top: 50%;
          left: 50%;
          background: @color-w;
          width: 3px;
          height: 50%;
          position: absolute;
          transform-origin: 50% 50%;
        }

        &::before {
          transform: translate(-50%, -50%) rotate(-45deg);
        }

        &::after {
          transform: translate(-50%, -50%) rotate(45deg);
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            background: @color-hover;
            cursor: pointer;
            box-shadow: @primary-btn-hover;
          }
        }
      }
    }


  }

  &.popup__open {
    opacity: 1;
    visibility: visible;

    .popup__container__window {
      transform: perspective(600) translateY(0) rotateX(0);
    }
  }

  &-content {
    display: flex;
    justify-content: flex-start; // align X
    align-items: flex-start; // align Y
    align-content: flex-start; // align Y ios
    flex-direction: column;
    gap: calc(@gap * 2);

    &__title {
      font: @fw-s-bold @headname-3/120% @font-primary;

      @media only screen and (max-width:  @medium), only screen and (max-device-width: @medium) {
        font-size:@headname-4;
      }

    }

    &__item {
      display: block;
      width: 100%;
      margin-bottom: calc(@gap * 2);

      p{
        font-size:@paragraph-1;

        @media only screen and (max-width:  @medium), only screen and (max-device-width: @medium) {
          font-size:@paragraph-2;
        }

      }
    }

    &__image {
      overflow: hidden;
      margin-bottom: calc(@gap * 2);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';
      }
    }

    &__footer {
      display: flex;
      justify-content: flex-end; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
      width: 100%;
      gap: calc(@gap * 2);
    }
  }
}





