.footer {
  display: flex;
  flex-direction: column;
  //overflow: hidden;
  position: relative;


  &__content {
    display: flex;
    flex-direction: column;
    background: @color-b1;
    color: @color-w;
    padding-top: calc(@gap*4);
    padding-bottom: calc(@gap*4);
    position: relative;
    overflow: hidden;

    .resolution-wrap {
      position: relative;

      .logo-bg-decor {
        position: absolute;
        pointer-events: none;
        width: 520px;
        height: 520px;
        top: 0;
        right: 0;
        transform: translate(20%, -10%);
        opacity: 0.07;
        z-index: 1;

        svg {
          width: inherit;
          height: inherit;
        }

        @media only screen and (max-width: @xxlarge),
        only screen and (max-device-width: @xxlarge) {
          top: auto;
          bottom: 0;
          right: 50%;
          transform: translate(50%,  31px);
        }

      }
    }

    &__nav {
      position: relative;
      z-index: 2;

    }

    &__more {
      display: flex;
      position: relative;
      z-index: 2;
      justify-content: center; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
      gap: calc(@gap*2);

      @media only screen and (max-width: @xmedium),
      only screen and (max-device-width: @xmedium) {
        flex-direction: column;
      }

      > * {
        display: flex;
        align-items: center; // align Y
        align-content: center; // align Y ios
        gap: calc(@gap*2);
        flex: 1 1 auto;

        @media only screen and (max-width: @xmedium),
        only screen and (max-device-width: @xmedium) {
          flex-direction: column;
          justify-content: center !important; // align X
          align-items: center; // align Y
          align-content: center; // align Y ios
        }


      }

      .logo-soc {
        justify-content: flex-start; // align X

        .logo {
          display: inline-flex;

          svg {
            width: 127px;
            height: calc(127px / 2.729);
            fill: @color-primary;
          }
        }

        .soc-networks {
          display: flex;
          gap: 4px;
          padding-top: 2px;

          a {
            text-decoration: none;
            display: flex;

            svg {
              width: 24px;
              height: 24px;
              fill: @color-b3;
            }
          }
        }
      }

      .download-app-btns {
        justify-content: flex-end; // align X

        p {
          padding-top: 2px;
          font: @fw-s-bold @paragraph-2/105% @font-primary;
          color: @color-b4;
          text-align: right;
        }

        &__wrap{
          display: flex;
          justify-content: center; // align X
          align-items: center; // align Y
          align-content: center; // align Y ios
          gap: calc(@gap*2);

          a {
            display: inline-flex;

            img {
              width: 120px;
              height: 40px;
              .anim_on(@anim);
            }

            @media (hover: hover) and (pointer: fine) {
              &:hover{
                img{
                  transform: scale(1.2);
                }
              }
            }
          }
        }
      }
    }
  }

  &__copyright {
    background: @color-b1 - 30%;
    display: flex;
    min-height: @footer-h;
    padding-top: @gap;
    padding-bottom: @gap;
    z-index: 5;


    .resolution-wrap {
      display: flex;
      flex-direction: row;
      gap: calc(@gap*2);
      justify-content: flex-start; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios

      @media only screen and (max-width: @xmedium),
      only screen and (max-device-width: @xmedium) {
        padding-left: @gap;
        padding-right: @gap;
      }
    }

    &__col-copy-text {
      color: @color-b2;
      font-size: @caption;
    }

    &__col-logos {
      display: flex;
      gap: @gap;

      a {
        display: inline-flex;
        opacity: 0.5;

        img{
          height: 30px;
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            opacity: 1;
          }
        }
      }
    }

    &__col-menu {
      margin-left: auto;
      display: flex;
      gap: calc(@gap*2);

      @media only screen and (max-width: @xmedium),
      only screen and (max-device-width: @xmedium) {
        display: flex;
        flex-direction: column;
        justify-content: flex-end; // align X
        align-items: flex-end; // align Y
        align-content: flex-end; // align Y ios
        min-width: 32px;
        min-height: 32px;
        position: absolute;
        bottom: 8px;
        right: 10px;
        padding-top: 0;
        padding-bottom: 0;
        transition: all 0.1s linear;
        gap: 0;
        border-radius: @br;
        cursor: pointer;
        
        &::after{
          position: absolute;
          bottom: 0;
          right: 0;
          display: flex;
          width: 32px;
          height: 24px;
          justify-content: center; // align X
          align-items: center; // align Y
          align-content: center; // align Y ios
          content: url('data:image/svg+xml;charset=utf-8,<svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.875 7.625C5.91146 7.22396 6.13021 7.00521 6.53125 6.96875H17.4688C17.8698 7.00521 18.0885 7.22396 18.125 7.625C18.0885 8.02604 17.8698 8.24479 17.4688 8.28125H6.53125C6.13021 8.24479 5.91146 8.02604 5.875 7.625ZM5.875 12C5.91146 11.599 6.13021 11.3802 6.53125 11.3438H17.4688C17.8698 11.3802 18.0885 11.599 18.125 12C18.0885 12.401 17.8698 12.6198 17.4688 12.6562H6.53125C6.13021 12.6198 5.91146 12.401 5.875 12ZM17.4688 17.0312H6.53125C6.13021 16.9948 5.91146 16.776 5.875 16.375C5.91146 15.974 6.13021 15.7552 6.53125 15.7188H17.4688C17.8698 15.7552 18.0885 15.974 18.125 16.375C18.0885 16.776 17.8698 16.9948 17.4688 17.0312Z" fill="rgb(89 97 109)"/></svg>');
        }

        > a{
          // display: none !important;
          width: 100%;
          justify-content: flex-end; // align X
          align-items: center; // align Y
          align-content: center; // align Y ios
          text-align: right;
          padding: 8px 24px;
          min-height: 24px;
          display: none;

          @media (hover: hover) and (pointer: fine) {
              &:hover{
                  background: @color-b1;
              }
          }
        }

        &.active{
          padding-top: 10px;
          padding-bottom: 32px;
          background: @color-b1 - 10%;

          > a{
            display: flex;
          }

          &::after{
            right: 16px;
            content: url('data:image/svg+xml;charset=utf-8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.0078 10.9453L17.7266 5.22656C18.0807 4.9349 18.4245 4.9349 18.7578 5.22656C19.0703 5.58073 19.0703 5.9349 18.7578 6.28906L13.0703 12.0078L18.7578 17.7266C19.0703 18.0807 19.0703 18.4245 18.7578 18.7578C18.4245 19.0703 18.0807 19.0703 17.7266 18.7578L12.0078 13.0703L6.28906 18.7578C5.9349 19.0703 5.58073 19.0703 5.22656 18.7578C4.9349 18.4245 4.9349 18.0807 5.22656 17.7266L10.9453 12.0078L5.22656 6.28906C4.9349 5.9349 4.9349 5.58073 5.22656 5.22656C5.58073 4.9349 5.9349 4.9349 6.28906 5.22656L12.0078 10.9453Z" fill="rgb(89 97 109)"/></svg>');
          }
        }
      }


      a {
        font-size: @caption;
        color: @color-w;
        text-decoration: none;
        opacity: .7;

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}

.footer-nav {
  display: flex;
  justify-content: flex-start; // align X
  align-items: flex-start; // align Y
  align-content: flex-start; // align Y ios
  padding-bottom: calc(@gap*2);
  margin-bottom: calc(@gap*2);
  border-bottom: 1px solid @color-b2;
  gap: calc(@gap*4) calc(@gap*2);
  flex-wrap: wrap;

  &__column {
    width: calc(25% - @gap*2);

    @media only screen and (max-width: 848px),
    only screen and (max-device-width: 848px) {
      width: calc(50% - @gap*2);
    }

    @media only screen and (max-width: 520px),
    only screen and (max-device-width: 520px) {
      width: 100%;
    }
         


    &__head-name {
      border-bottom: 1px solid @color-b2;
      padding-bottom: calc(@gap*2);
      margin-bottom: @gap;
      position: relative;

      h5 {
        font: @fw-bold @headname-5/100% @font-primary;
        color: @color-b3;
      }

      &::after {
        content: '';
        display: block;
        width: 40px;
        height: 3px;
        background: @color-b2;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }

    &__links-list {
      display: flex;
      justify-content: flex-start; // align X
      align-items: flex-start; // align Y
      align-content: flex-start; // align Y ios
      flex-direction: column;

      a {
        display: flex;
        width: 100%;
        min-height: 32px;
        padding-top: 8px;
        padding-bottom: 8px;
        text-decoration: none;
        color: @color-w;
        opacity: 0.7;
        font-size: @paragraph-2;

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            opacity: 1;
          }
        }

      }

    }
  }
}

