//============================================================================
//+++++++++++++++++++++++++++++ FORMS RESET +++++++++++++++++++++++++++++++++
//============================================================================

//removes the outline of active buttons and forms /
input:not[type=checkbox] { //NO IOS SHADOW AND b_radius
  -webkitw: none;
  -webkit-box-appearance: none;
  box-shado-shadow: none;
  border-radius: 0 !important;
  -webkit-appearance: none;
}

input, textarea {
  outline: none;
}

input:active, textarea:active {
  outline: none;
}

*:focus {
  outline: none;
}

textarea {
  resize: none;
  color: @color-b1;
}

button::-moz-focus-inner {
  border: 0;
}

a.active.focus,
a.active:focus,
a.focus,
a:active.focus,
a:active:focus,
a:focus,
button:active,
button.active.focus,
button.active:focus,
button.focus,
button:active.focus,
button:active:focus,
button:focus,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: 0 !important;
  outline-color: transparent !important;
  outline-width: 0 !important;
  outline-style: none !important;
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);

  button:active, button:focus {
    outline: none !important;
  }

}

*:focus, *:active,
*::-moz-focus-inner {
  outline: none !important;
  border: 0;
  outline: 0 !important;
  outline-offset: 0 !important;
}