
.caps {
  text-transform: uppercase;
}

// TEXT BOX
.text-box, .text-wrap {
  width: 100%;

  > br {
    position: relative;
    width: 100%;
    display: block;
  }

  .video-wrap {
    display: flex;
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    align-content: center;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: @br;
    background: @color-b6;

    iframe {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }

  h1 {
    font: @fw-bold @headname-1/98% @font-primary;
    letter-spacing: 0.14px;
    position: relative;
    margin-bottom: @gap;

    @media only screen and (max-width: @medium), only screen and (max-device-width: @medium) {
      font-size: @headname-2;
    }

  }

  h2 {
    font: @fw-s-bold @headname-2/98% @font-primary;
    position: relative;
    margin-bottom: 10px;
    @media only screen and (max-width: @medium), only screen and (max-device-width: @medium) {
      font-size: @headname-3;
    }
  }

  h3 {
    font: @fw-s-bold @headname-3/105% @font-primary !important;
    position: relative;
    margin-bottom: 10px;
    @media only screen and (max-width: @medium), only screen and (max-device-width: @medium) {
      font-size: @headname-4;
    }
  }

  h4 {
    font: @fw-s-bold @headname-4/116% @font-primary !important;
    position: relative;
    margin-bottom: 10px;
    @media only screen and (max-width: @medium), only screen and (max-device-width: @medium) {
      font-size: @headname-5;
    }
  }

  h5 {
    font: @fw-s-bold @headname-5/116% @font-primary !important;
    position: relative;
    margin-bottom: 10px;
    @media only screen and (max-width: @medium), only screen and (max-device-width: @medium) {
      font-size: @headname-6;
    }
  }

  .date {
    color: @color-b2;
    margin-bottom: calc(@gap * 2);

    &::before {
      content: '\4d';
      color: @color-b3;
      .font-icon;
    }
  }

  img, p > img {
    position: relative;
    max-width: 100% !important;
  }

  p {
    font: @fw-reg @paragraph-1/140% @font-primary;
    margin-bottom: calc(@gap * 2);

    @media only screen and (max-width: @xmedium),
    only screen and (max-device-width: @xmedium) {
        font-size: @paragraph-2;
    }

  }


  .list-links {
    display: flex;
    flex-wrap: wrap;
    gap: @gap;
    padding-bottom: calc(@gap * 4);
  }

  .big-img {
    display: block !important;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    border-radius: @br;
    //padding-bottom: 56.25%;
    position: relative;
    margin-bottom: calc(@gap * 2);

    img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover !important;
      object-fit: cover !important;
      font-family: 'object-fit: cover;';
      //position: absolute;
      //top: 0;
      //left: 0;
    }
  }

  .img-left, .img-right {
    display: inline-flex;
    max-width: calc(33.33% + @gap * 2);
    margin-bottom: calc(@gap * 2);
    overflow: hidden;
    border-radius: @br;
    position: relative;
    top: @gap;
  }

  .img-left {
    float: left;
    margin-right: @gap*2;
  }

  .img-right {
    float: right;
    margin-left: @gap*2;
  }

  [class*='prev-img'] {
    width: 33.33%;
    display: flex;

    > div {
      overflow: hidden;
      display: flex;
      flex: 1 0 120px;
      width: 100%;
      padding-bottom: 66.25%;
      position: relative;
      margin-bottom: calc(@gap * 2);
      border-radius: @br;

      img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover !important;
        object-fit: cover !important;
        font-family: 'object-fit: cover;';
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &.prev-img__left {
      float: left;
      margin-right: calc(@gap * 2);
    }

    &.prev-img__right {
      float: right;
      margin-left: calc(@gap * 2);
    }
  }

  .quote,
  .quote_c,
  blockquote {
    position: relative;
    margin-bottom: calc(@gap * 4);
    font-size: 16px;
    display: inline-block;
    width: 100%;
    background: @color-b6;
    //background: @color-b5 ;
    border-radius: @br;
    padding: calc(@gap * 2) calc(@gap * 2) calc(@gap * 2) 76px;
    color: @color-b1;


  }

  .quote_c {
    text-align: center;
    padding-left: 20px !important;
  }

  .quote,
  blockquote {
    &::before {
      content: "";
      width: 60px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      display: block;
      background: @color-b5;
      border-radius: @br 0 0 @br;
    }

    &::after {
      content: url('data:image/svg+xml;charset=utf-8,<svg width="34" height="25" viewBox="0 0 34 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.34375 24.6263H4.75C4.05729 24.6263 3.48828 24.4037 3.04297 23.9584C2.59766 23.5131 2.375 22.9441 2.375 22.2513C2.375 21.5586 2.59766 20.9896 3.04297 20.5443C3.48828 20.099 4.05729 19.8763 4.75 19.8763H5.34375C6.53125 19.8269 7.52083 19.431 8.3125 18.6888C9.05469 17.8972 9.45052 16.9076 9.5 15.7201V15.1263H4.75C3.41406 15.0769 2.30078 14.6068 1.41016 13.7162C0.519531 12.8256 0.0494792 11.7123 0 10.3763V5.62634C0.0494792 4.29041 0.519531 3.17712 1.41016 2.2865C2.30078 1.39587 3.41406 0.925822 4.75 0.876343H9.5C10.8359 0.925822 11.9492 1.39587 12.8398 2.2865C13.7305 3.17712 14.2005 4.29041 14.25 5.62634V15.7201C14.2005 18.2435 13.3346 20.3464 11.6523 22.0287C9.97005 23.711 7.86719 24.5769 5.34375 24.6263Z" fill="rgb(181, 186, 199)"/><path d="M24.5938 24.6263H24C23.3073 24.6263 22.7383 24.4037 22.293 23.9584C21.8477 23.5131 21.625 22.9441 21.625 22.2513C21.625 21.5586 21.8477 20.9896 22.293 20.5443C22.7383 20.099 23.3073 19.8763 24 19.8763H24.5938C25.7812 19.8269 26.7708 19.431 27.5625 18.6888C28.3047 17.8972 28.7005 16.9076 28.75 15.7201V15.1263H24C22.6641 15.0769 21.5508 14.6068 20.6602 13.7162C19.7695 12.8256 19.2995 11.7123 19.25 10.3763V5.62634C19.2995 4.29041 19.7695 3.17712 20.6602 2.2865C21.5508 1.39587 22.6641 0.925822 24 0.876343H28.75C30.0859 0.925822 31.1992 1.39587 32.0898 2.2865C32.9805 3.17712 33.4505 4.29041 33.5 5.62634V15.7201C33.4505 18.2435 32.5846 20.3464 30.9023 22.0287C29.2201 23.711 27.1172 24.5769 24.5938 24.6263Z" fill="rgb(181, 186, 199)"/></svg>');

      //content: "\49"; //.dripicons-broadcast
      width: 34px;
      height: 25px;
      position: absolute;
      color: rgba(@color-w, 1);
      //.font-icon;
      justify-content: center; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
      //font-size: 23px;
      top: 20px;
      left: 12px;
      z-index: 2;
      display: block;
    }
  }

  ul:not(.todo-list):not(.numeric-list):not(.slick-dots) {
    list-style-type: none;
    counter-reset: num;
    margin: 0 auto calc(@gap * 4) auto;

    > li {
      position: relative;
      list-style: none;
      min-height: 25px;
      padding-left: 18px;
      //color: @color-b1;

      &:not(:last-of-type) {
        margin-bottom: calc(@gap * 2);
      }

      &:before {
        content: '';
        width: 8px;
        height: 8px;
        position: absolute;
        left: 2px;
        top: 7px;
        z-index: 3;
        background: rgba(@color-primary, 1);
        border-radius: 100%;
      }

      > * {
        margin: 10px auto 5px;

        > li {

          &:before {
            content: '';
            width: 8px;
            height: 8px;
            position: absolute;
            left: 2px;
            top: 7px;
            z-index: 3;
            background: @color-b3;
            border-radius: 100%;
          }
        }
      }
    }
  }

  ul.todo-list {
    list-style-type: none;
    counter-reset: num;
    margin: 0 auto calc(@gap * 4) auto;

    > li {
      position: relative;
      list-style: none;
      margin-bottom: 15px;
      min-height: 25px;
      padding-left: 18px;
      //color: @color-b1;

      label {
        position: absolute;
        left: 0;
        top: -2px;
        margin-right: 10px;
      }
    }
  }

  ol {
    list-style-type: none;
    counter-reset: num;
    margin: 0 auto calc(@gap * 4) auto;

    > li {
      position: relative;
      list-style: none;
      margin-bottom: 15px;
      min-height: 25px;
      padding-left: 30px;
      //color: @color-b1;

      &::before {
        content: counter(num) ' ' !important;
        counter-increment: num !important;
        color: @color-w;
        display: flex;
        background: @color-primary;
        width: 23px;
        height: 23px;
        justify-content: center; // align X
        align-items: center; // align Y
        align-content: center; // align Y ios
        position: absolute;
        line-height: 17px;
        left: 0;
        top: 0;
        font: @fw-bold @caption / 100% @font-primary;
        text-align: center;
        border-radius: @br;
      }
    }
  }

  .action-color {
    color: @color-primary;
  }

  .anchors {
    a {
      text-decoration: none;
      color: @color-b4;
      border-bottom: 1px dashed rgba(@color-w, .2);

      &:hover {
        border-bottom: 1px dashed rgba(@color-w, .8);
      }
    }
  }

  .numeric-hn {
    position: relative;
    display: inline-block;
    //align-content: flex-start;
    //align-items: flex-start;
    margin-bottom: calc(@gap * 2);
    padding-left: 60px;

    > span {
      display: flex;
      align-self: stretch;
      min-height: 100%;
      align-content: center;
      align-items: center;
      font: @fw-s-bold 32px/120% @font-primary;
    }

    @media only screen and (max-width: 540px), only screen and (max-device-width: 540px) {
      > span {
        font: @fw-s-bold 20px/120% @font-primary !important;
      }
    }

    &::before {
      content: attr(data-numeric);
      text-align: left;
      margin-right: calc(@gap * 2);
      color: @color-hover;
      display: flex;
      flex: 0 0 46px;
      width: 46px;
      min-width: 46px;
      height: 46px;
      align-content: center;
      align-items: center;
      justify-content: center;
      position: absolute;
      line-height: 17px;
      left: -4px;
      top: -10px;
      font-size: 20px;
      font-weight: @fw-bold;
      border-radius: @br;
    }
  }

  .numeric-sub {
    position: relative;
    display: block;
    //align-content: flex-start;
    //align-items: flex-start;
    margin-bottom: calc(@gap * 2);
    padding-left: 80px;

    &::before {
      content: attr(data-numeric);
      text-align: left;
      margin-right: calc(@gap * 2);
      color: @color-b1;
      display: flex;
      flex: 0 0 46px;
      width: 62px;
      min-width: 46px;
      height: 46px;
      align-content: center;
      align-items: center;
      justify-content: center;
      position: absolute;
      line-height: 17px;
      left: 5px;
      top: -10px;
      font-size: 16px;
      font-weight: @fw-bold;
      border-radius: @br;
    }
  }

  .numeric-list {

    > li {
      padding-left: 65px;
      margin-bottom: calc(@gap * 4);
      position: relative;

      > ul.numeric-list {
        > li {
          font: @fw-reg 15px/125% @font-primary !important;
          color: @color-b3;

          .numeric-list__number {
            font-size: 14px !important;
          }

          > ul.numeric-list {
            > li {
              padding-left: 90px !important;

              .numeric-list__number {
                font-size: 13px !important;
              }
            }
          }
        }
      }
    }

    &__number {
      margin-right: calc(@gap * 2);
      background: @color-b1;
      display: inline-flex;
      width: auto;
      height: auto;
      align-content: center;
      align-items: center;
      justify-content: center;
      position: absolute;
      padding: 8px 11px 6px 13px;
      left: 0;
      top: -3px;
      color: @color-b3;
      font-size: 16px;
      line-height: 16px !important;
      font-weight: @fw-s-bold;
      border-radius: @br;
    }
  }

  .table-scroll {
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
  }

  .table-theme-1 {
    width: 100%;
    box-shadow: 0 5px 6px -2px rgba(0, 0, 0, 0.2);

    thead {
      background: @color-primary;

      th {
        color: @color-w;
        text-align: left;

        &:last-child {
          border-radius: 0 8px 0 0;
        }

        &:first-child {
          border-radius: 8px 0 0 0;
        }
      }
    }

    tbody {
      tr {
        td {
          text-align: left;
          border-right: 1px solid @color-b5;
          border-bottom: 1px solid @color-b5;

          &:first-child {
            border-left: 1px solid @color-b5;
          }
        }

        &:last-of-type {
          td {
            &:last-of-type {
              border-radius: 0 0 8px 0;
            }

            &:first-child {
              border-radius: 0 0 0 8px;
            }
          }
        }
      }

    }

    th, td {
      padding: 8px 10px;
    }

  }

  a {
    display: inline-block;
    max-width: 100%;
    word-break: break-all; /* Дозволяє розривати слово (посилання) */
    overflow-wrap: break-word; /* Переносить слово, якщо воно не вміщується */

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: @color-hover;
      }
    }
  }

}


// Adaptive fonts
.adaptive-font(@pcSize, @mobSize) {
  font-size: calc(@mobSize + (@pcSize - @mobSize) * ((100vw - 320px) / (1920 - 320)));
}


// LISTS STYLE
//=============================================================================
[class*='list_v'] {
  margin: 0 auto 20px;

  li {
    position: relative;
    margin-bottom: 15px;
    padding-left: 25px;
  }
}

.list_v1 {
  li {
    &:before {
      content: '\53'; //.dripicons-checkmark
      width: 20px;
      height: 20px;
      position: absolute;
      left: -3px;
      top: 0;
      .font-icon;
      z-index: 3;
      display: flex;
      justify-content: center; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
      font-size: 17px;
      text-rendering: auto;
      font-style: normal !important;
      color: @color-hover;
    }
  }
}

.list_v2 {
  li {
    &:before {
      content: "\39"; // .dripicons-cross
      width: 20px;
      height: 20px;
      position: absolute;
      left: 0;
      top: 4px;
      z-index: 3;
      display: flex;
      justify-content: center; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
      font-size: 16px;
      text-rendering: auto;
      font-style: normal !important;
      color: @color-error !important;
      .font-icon;
    }
  }
}

.list_v3 {
  list-style-type: none;
  counter-reset: num;
  margin-bottom: 40px;

  > li {
    list-style: none;
    margin-bottom: 15px;
    min-height: 25px;

    &::before {
      content: counter(num) ' ' !important;
      counter-increment: num !important;
      color: @color-w;
      background: @color-primary;
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
      position: absolute;
      line-height: 17px;
      left: -8px;
      top: 0;
      font-size: 13px;
      font-weight: 600;
      border-radius: @br;

    }
  }
}

.list_v4 {
  li {
    padding: 0 0 14px 0;
    border-bottom: 1px solid @color-b5;
  }
}

.list_v5 {
  > li {
    margin-bottom: 10px;

    &::before {
      content: "";
      width: 6px;
      height: 6px;
      position: absolute;
      left: 6px;
      top: 8px;
      border-radius: 100%;
      z-index: 3;
      background: @color-hover;
    }

    > ul {

      margin: 13px auto 0;

      > li {
        width: 100%;

        &::before {
          content: "";
          width: 6px;
          height: 6px;
          position: absolute;
          left: 6px;
          top: 8px;
          border-radius: 100%;
          z-index: 3;
          background: @color-b5;
        }
      }
    }
  }
}

.list_v6 {
  > li {
    margin-bottom: 8px;
    padding-left: 13px;

    &::before {
      content: '\e01c'; //.dripicons-media-record
      width: 10px;
      height: 10px;
      position: absolute;
      left: -3px;
      top: 3px;
      font-size: 6px;
      .font-icon;
      z-index: 3;
      display: flex;
      justify-content: center; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
      color: @color-primary;
    }

    > ul {

      margin: 13px auto 0;

      > li {
        width: 100%;

        &::before {
          content: '\e01c';
          background-position: 50% -20px * 18;
          width: 10px;
          height: 10px;
          position: absolute;
          left: -3px;
          top: 1px;
          font-size: 9px;
          .font-icon;
          z-index: 3;
          display: flex;
          justify-content: center; // align X
          align-items: center; // align Y
          align-content: center; // align Y ios
          color: @color-b5;
        }
      }
    }
  }
}


