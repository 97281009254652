body .preloader{


    display: block !important;
    z-index:998 !important;
    position: fixed !important;
    pointer-events: none;
    top: 0;
    right: 0;
    left: 0;
    background:@color-w;
    width: 100%;
    height: 100vh;
    transition: all 0.5s ease-in-out;
    visibility: visible !important;
    opacity: 1 !important;


  > div{
    opacity: 1;
    position: fixed;
    pointer-events: none;
    display: block;
    width: 40px;
    height: 40px;
    z-index: 999;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.5s ease-in-out;
    animation: loader 4s infinite linear;

    svg{
      width: 40px;
      height: 40px;
      min-width: 40px;
      min-height: 40px;
    }
  }
}

@keyframes loader {
  0%,100%{
    transform: translate(-50%, -50%) scale(1);
  }
  50%{
    transform: translate(-50%, -50%) scale(1.5);
  }
}

.mw-text {
  max-width: @width-text-limit;
}

.mw-form {
  max-width: 420px;
}

.align-r {
  justify-self: flex-end;
}

.align-l {
  justify-self: flex-start;
}

.align-c {
  justify-self: center;
}

.align-stretch {
  justify-self: stretch;
}

.block-style-css {
  background: @color-w;
  border: 1px solid @color-b5;
  border-radius: @br;
  box-shadow: @shadow-light;
}

// crumbs in header page
.crumbs {
  display: flex;
  gap: @gap*3;
  justify-content: flex-start; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios
  margin-bottom: @gap;
  scrollbar-width: none; /* Firefox */
  overflow: hidden;
  overflow-x: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Edge */
  }

  > a:not(:first-child) {
    border-bottom: 1px dashed @color-b3;
  }

  > * {
    display: inline-flex;
    flex-wrap: nowrap;
    line-height: 100%;
    position: relative;
    color: @color-b2;
    font-size: @caption;
    text-decoration: none;
    white-space: nowrap;

    &:not(:first-child) {
      top: 1px;
    }

    &:first-child {
      text-decoration: none;
      display: inline-flex;
      justify-content: center; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios

      svg {
        width: 16px;
        height: 16px;
        fill: @color-b3;
      }
    }

    &:not(:last-child):not(:only-child) {
      &::after {
        content: '';
        width: 1px;
        height: 16px;
        transform-origin: 50% 50%;
        background: @color-b3;
        transform: rotate(20deg) translateY(-52%);
        position: absolute;
        right: -@gap*1.2;
        top: 50%;
        display: block;
      }
    }
  }
}

// Preseader
.pre-title {
  font-size: @caption;
  text-transform: uppercase;

  > * {
    letter-spacing: 0.08rem;
  }
}

// Main header on page
.page-header {
  background: @color-b6;
  overflow: hidden;
  padding-top: calc(@gap * 2);
  padding-bottom: calc(@gap * 2);

  .resolution-wrap {
    position: relative;

    > *:not(.page-header__logo-decor) {
      position: relative;
      z-index: 2;
    }

  }

  &__title {
    margin-bottom: @gap;

    > * {
      font: @fw-bold @headname-1/100% @font-primary;

      @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
        font-size: @headname-2;
      }

    }
  }

  &__short-description {
    display: flex;
    max-width: @width-text-limit;

    > * {
      font: @fw-reg @paragraph-2/130% @font-primary;
    }
  }

  &__logo-decor {
    display: inline-flex;
    position: absolute;
    width: 268px;
    height: 268px;
    left: 0;
    top: 50%;
    z-index: 1;
    transform: translate(-5%, -50%);

    svg {
      width: inherit;
      height: inherit;
    }
  }
}

// title in center section
.section-title {
  display: flex;
  justify-content: center; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios
  padding-bottom: calc(@gap * 2);
  max-width: @width-text-limit;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  > * {
    font: @fw-bold @headname-2/105% @font-primary;


    @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
      font-size: @headname-3;
    }

  }
}

.section-sub-title {
  display: flex;
  justify-content: center; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios
  padding-bottom: calc(@gap * 2);
  max-width: @width-popup-limit;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  > * {
    font: @fw-reg @paragraph-1/125% @font-primary;

    @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
      font-size: @paragraph-2;
    }

  }
}

.nowrap {
  white-space: nowrap;
}

// контент з сайдбаром
.content-with-sidebar {
  padding-top: @gap*4;
  padding-bottom: @gap*8;
  position: relative;
  border-bottom: 1px solid @color-b6;

  .resolution-wrap {
    flex: 1 1 @xmedium;
    display: flex;
    flex-wrap: wrap;
    row-gap: @gap*8;

    > *:not(.content-with-sidebar__sidebar) {
      flex: 0 0 calc(100% - @sidebar - @gap * 3);
      min-width: calc(100% - @sidebar - @gap * 3);
      margin-right: auto;

      @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
        flex: 1 1 auto;
        width: 100%;
      }

      // title in center section
      .section-title {
        display: flex;
        justify-content: flex-start; // align X
        align-items: flex-start; // align Y
        align-content: flex-start; // align Y ios
        padding-bottom: calc(@gap);
        max-width: @width-text-limit;
        text-align: left;

        > * {
          font: @fw-bold @headname-3/105% @font-primary;


          @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
            font-size: @headname-2;
          }

        }
      }
    }

    .content-with-sidebar__sidebar {
      max-width: @sidebar;
      min-width: @sidebar;
      margin-left: auto;
      flex: 0 0 @sidebar; /* Фіксована ширина сайдбара */
      position: sticky;
      align-items: start;
      top: @header-h;
      display: flex;
      flex-direction: column;
      gap: @gap*4;
      padding-bottom: @gap*3;
      align-self: flex-start;

      @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
        top: 0;
        //order: 1;
        flex: 1 1 auto;
        width: 100%;
        max-width: none;
        min-width: auto;
        position: relative;

        &__box {
          width: 100%;

          .sidebar-title {
            margin-bottom: @gap * 2;
            border-bottom: none;
          }
        }
      }

      &__box {
        padding-bottom: @gap*3;

        .sidebar-title {
          padding-bottom: @gap;
          margin-bottom: @gap;
          border-bottom: 1px solid @color-b5;

          > * {
            font-size: @headname-4;
            font-weight: @fw-s-bold;
          }
        }
      }
    }
  }

}

.contacts-container {
  display: flex;
  max-width: 100%;
  flex-direction: column;
  gap: @gap*2;

  &__contact-info {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    gap: @gap*2;

    > * {

      > * {
        position: relative;
        display: flex;
        padding-top: 14px;
        gap: @gap;

        .label {
          position: absolute;
          top: -6px;
          color: @color-b3;
          font-size: @caption;
        }

        .info {
          display: inline-flex;
          min-height: 24px;
          padding-top: 4px;
          font-size: @paragraph-2;

          a.phone-number {
            display: inline-flex;
            gap: @gap;
            text-decoration: none;
            color: @color-b1;

            svg {
              position: relative;
              top: -2px;
              width: 24px;
              height: 24px;
              fill: @color-b3;
              .anim_on(@anim);
            }

            @media (hover: hover) and (pointer: fine) {
              &:hover {
                svg {
                  fill: @color-primary;
                }
              }
            }
          }
        }
      }

    }

    .info.close {
      font-weight: @fw-s-bold;
      color: rgba(@color-error, 1);
    }

    .info.open {
      font-weight: @fw-s-bold;
      color: rgba(@color-secondary, 0.8);
    }

    &__work-time {
      .wort-time-list {
        display: table;
        padding-top: 0;

        > * {
          display: table-row;
          text-align: left;
          font-size: @caption;

          &.active {
            span {
              font-weight: @fw-bold !important;
            }
          }

          .day {
            display: table-cell;
            padding-right: @gap*2;
          }

          .open, .close {
            display: table-cell;
            white-space: nowrap;
          }

          .close {
            color: rgba(@color-b1, 1);

          }
        }
      }
    }

    &__services-provided {
      .services-list {
        display: flex;
        gap: calc(@gap / 2);
        flex-wrap: wrap;
        align-items: start;

        > * {
          width: auto;
          flex: 0 1 auto;
          display: inline-flex;
          padding: 8px;
          align-items: start;
          border-radius: @br;
          border: 1px solid @color-b5;
          font-size: @caption;
          line-height: 105%;
        }
      }
    }

  }
}

.copy-to-buffer {
  display: inline-flex;
  border: none;
  background: none;

  svg {
    width: 24px;
    height: 24px;
    fill: @color-b3;
    .anim_on(@anim);
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      svg {
        fill: @color-primary;
      }
    }
  }

  &:active {
    svg {
      transform: translateY(1px);
    }
  }
}

.small-alerts {
  display: flex;
  border: 1px solid @color-secondary;
  background: rgba(@color-secondary, 0.3);
  backdrop-filter: blur(10px);
  border-radius: @br;
  top: 10px;
  right: 10px;
  color: @color-b1;
  padding: @gap*2 @gap*3;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  .anim_on(@anim);
  transform: translateX(50%);
  z-index: 90;

  &.active {
    opacity: 1;
    transform: translateX(0);
  }
}

// reviews
.reviews {
  display: flex;
  gap: @gap;
  margin-bottom: calc(@gap * 4);
  flex-wrap: wrap;

  &__item {
    padding: calc(@gap * 2);
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
    gap: @gap;
    .block-style-css;

    &__head {
      display: flex;
      gap: @gap;

      @media only screen and (max-width: 480px), only screen and (max-device-width: 480px) {
        flex-wrap: wrap;
        gap: 0;

      }


      .author {
        padding-top: 2px;
        font: @fw-s-bold @paragraph-1/120% @font-primary;

        white-space: nowrap; /* Забороняє перенос тексту */
        overflow: hidden; /* Ховає текст, що виходить за межі контейнера */
        text-overflow: ellipsis; /* Додає три крапки (...) в кінці тексту */
        /* Ширина контейнера (за потреби змініть) */
        max-width: 120px;
        display: inline-block; /* Або inline-block для рядкових елементів */
      }

      .vis-rating {
        margin-left: auto;
      }

      time {
        width: auto;
        font-size: @caption;
        color: @color-b2;
        display: inline-flex;
        padding-top: 3px;
        white-space: nowrap;
        gap: calc(@gap / 2);
        top: 2px;

        &::before {
          position: relative;
          top: 1px;
          content: url('data:image/svg+xml;charset=utf-8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.5625 3.5H9.9375V2.5625C9.96875 2.21875 10.1562 2.03125 10.5 2C10.8438 2.03125 11.0312 2.21875 11.0625 2.5625V3.5H12C12.4219 3.51562 12.7734 3.66406 13.0547 3.94531C13.3359 4.22656 13.4844 4.57812 13.5 5V12.5C13.4844 12.9219 13.3359 13.2734 13.0547 13.5547C12.7734 13.8359 12.4219 13.9844 12 14H4.5C4.07812 13.9844 3.72656 13.8359 3.44531 13.5547C3.16406 13.2734 3.01562 12.9219 3 12.5V5C3.01562 4.57812 3.16406 4.22656 3.44531 3.94531C3.72656 3.66406 4.07812 3.51562 4.5 3.5H5.4375V2.5625C5.46875 2.21875 5.65625 2.03125 6 2C6.34375 2.03125 6.53125 2.21875 6.5625 2.5625V3.5ZM4.125 12.5C4.14062 12.7344 4.26562 12.8594 4.5 12.875H12C12.2344 12.8594 12.3594 12.7344 12.375 12.5V6.5H4.125V12.5Z" fill="rgb(156, 163, 174)"/></svg>');
        }

        @media only screen and (max-width: 480px), only screen and (max-device-width: 480px) {
          width: 100%;
          order: 99;

        }
      }
    }

    &__body {
      > * {
        font-size: @paragraph-1;

        @media only screen and (max-width: 480px), only screen and (max-device-width: 480px) {
          font-size: @paragraph-2;
        }
      }
    }
  }

}

// blogs in sidebar
.news-preview {
  display: flex;
  flex-direction: column;
  row-gap: calc(@gap * 2);
}

.news-preview__item {
  display: flex;
  flex-direction: column;
  //gap: @gap;
  margin-bottom: calc(@gap * 4);

  a {
    text-decoration: none;
    color: @color-b1;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: @color-b2;
      }
    }
  }

  h2 {
    a {
      font-size: @headname-5;
      font-weight: @fw-s-bold;
    }
  }

  time {
    width: auto;
    font-size: @caption;
    color: @color-b2;
    display: inline-flex;
    padding-top: 3px;
    white-space: nowrap;
    gap: calc(@gap / 2);
    top: 2px;

    &::before {
      position: relative;
      top: 1px;
      content: url('data:image/svg+xml;charset=utf-8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.5625 3.5H9.9375V2.5625C9.96875 2.21875 10.1562 2.03125 10.5 2C10.8438 2.03125 11.0312 2.21875 11.0625 2.5625V3.5H12C12.4219 3.51562 12.7734 3.66406 13.0547 3.94531C13.3359 4.22656 13.4844 4.57812 13.5 5V12.5C13.4844 12.9219 13.3359 13.2734 13.0547 13.5547C12.7734 13.8359 12.4219 13.9844 12 14H4.5C4.07812 13.9844 3.72656 13.8359 3.44531 13.5547C3.16406 13.2734 3.01562 12.9219 3 12.5V5C3.01562 4.57812 3.16406 4.22656 3.44531 3.94531C3.72656 3.66406 4.07812 3.51562 4.5 3.5H5.4375V2.5625C5.46875 2.21875 5.65625 2.03125 6 2C6.34375 2.03125 6.53125 2.21875 6.5625 2.5625V3.5ZM4.125 12.5C4.14062 12.7344 4.26562 12.8594 4.5 12.875H12C12.2344 12.8594 12.3594 12.7344 12.375 12.5V6.5H4.125V12.5Z" fill="rgb(156, 163, 174)"/></svg>');
    }

  }

  p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    line-height: 1.2; /* Висота рядків */
    max-height: calc(1.2em * 3); /* Забезпечення обмеження висоти */
  }
}

.btn-load-more {
  display: inline-flex;
  width: 100%;
  justify-content: center; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios
}

// stars rating vesualisation
.vis-rating {
  display: inline-flex;
  gap: @gap;
  justify-content: flex-start; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios

  &__stars {
    order: 2;
    display: flex;
    justify-content: flex-start; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios


    .stars-svg {
      width: 80px;
      min-width: 80px;
      height: calc(80px / 5.9);
      position: relative;
      top: -1px;


      .stars-mask {
        fill: blanchedalmond;
      }

      .stars-scale {
        transform: translateX(-100%);
        fill: @color-attention;
      }

      .stars-border {
        stroke: @color-attention;
      }
    }
  }

  &__reviews-qty {
    color: @color-b3;
    order: 1;
    font-size: @caption;

  }
}

// section landing main banner
.landing-main-banner {
  display: inline-flex;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-color: rgb(@color-b6);
  background-image: url(" @{img}landing-banners/bg-image.jpg"),
  url(" /parking@{img}landing-banners/bg-image.jpg");
  background-size: cover !important;
  padding-bottom: calc(@gap * 3);
  box-sizing: border-box;
  position: relative;
  z-index: 4;

  .resolution-wrap {
    min-height: 340px;
    display: flex;
    justify-content: flex-start; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    gap: calc(@gap * 4);

    @media only screen and (max-width: 720px), only screen and (max-device-width: 720px) {
      flex-direction: column;
    }

  }

  .welcome-slider {
    max-width: 100%;
    display: flex;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    z-index: 3;
    position: relative;
    overflow: visible;

    .slick-list {
      overflow: visible;
      max-width: 100% !important;

      .slick-slide {
        .anim_on(@anim);
      }

      .slick-slide.active-slide {
        opacity: 1;
        position: relative;
      }

      .slick-slide:not(.slick-active) {

      }
    }

    .landing-main-banner__content {
      min-height: 480px;
      display: flex;
      flex-direction: column;
      justify-content: center; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
    }

    .landing-main-banner__content__image {
      position: absolute;
      z-index: 2;
      width: 700px;
      height: calc(700px / 1.4);
      top: 50%;
      left: 100%;
      transform: translate(-50%, -50%);

      @media only screen and (max-width: @medium), only screen and (max-device-width: @medium) {
        width: 620px;
        height: calc(620px / 1.4);
        transform: translate(-40%, -50%);
      }

    }

  }

  .content-wrapper {
    display: inline-flex;
    flex-direction: column;
    justify-content: center; // align X
    align-items: flex-start; // align Y
    align-content: flex-start; // align Y ios
    width: 100%;
    flex: 1 1 auto;
    margin-right: auto;
    max-width: 60%;
    position: relative;
    z-index: 3;

    @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
      max-width: 50% !important;
    }
    @media only screen and (max-width: @medium), only screen and (max-device-width: @medium) {
      max-width: 70% !important;
    }

  }

  &.with-form .content-wrapper {
    max-width: none;

    @media only screen and (max-width: 720px), only screen and (max-device-width: 720px) {
      text-align: center;
      max-width: none !important;
      justify-content: center; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
    }

  }

  &__content {
    display: flex;
    justify-content: center; // align X
    align-items: flex-start; // align Y
    align-content: flex-start; // align Y ios
    flex-direction: column;
    gap: calc(@gap * 4);
    position: relative;
    flex: 1 1 auto;
    z-index: 4;


    &.image-car {
      .landing-main-banner__content__image {
        @media only screen and (max-width: @medium), only screen and (max-device-width: @medium) {
          width: 520px;
          height: calc(420px / 1.4);
          transform: translate(-40%, -16%);
        }
      }
    }

    &__image {
      position: absolute;
      z-index: 2;
      width: 700px;
      height: calc(700px / 1.4);
      top: 0;
      left: 100%;
      transform: translate(-50%, -13%);

      img {
        width: 100%;
      }

      @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
        width: 620px !important;
        height: calc(620px / 1.4) !important;
        transform: translate(-45%, -16%);
      }
      @media only screen and (max-width: @medium), only screen and (max-device-width: @medium) {
        width: 320px !important;
        height: calc(320px / 1.4) !important;
        transform: translate(-40%, -14%);
      }

    }

    &__title {
      margin-bottom: @gap;

      > h1 {
        font: @fw-h-bold @headname-1/100% @font-primary;

        @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
          font-size: @headname-2;
        }
        @media only screen and (max-width: @medium), only screen and (max-device-width: @medium) {
          font-size: @headname-3;
        }
      }
    }

    &__description {

      > p {
        font: @fw-reg @paragraph-1/140% @font-primary;

        @media only screen and (max-width: @medium), only screen and (max-device-width: @medium) {
          font-size: @paragraph-2;
        }
      }
    }

    &__button {
      margin-top: calc(@gap * 2);
      justify-content: flex-start;

      > * {
        flex: 0 0 auto;
        display: inline-flex;
        margin-right: auto;
      }

    }

    .select-state {
      display: flex;
      position: relative;
      width: 100%;
      margin-top: calc(@gap * 4);
      border: 1px solid rgba(@color-w, .5);
      gap: calc(@gap * 4);
      z-index: 3;
      background: rgba(@color-w, 0.8);
      backdrop-filter: blur(7px);
      border-radius: calc(@br * 2);
      box-shadow: @shadow-light;
      padding: @gap*3 @gap*4 @gap*3 @gap*8;
      justify-content: center; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios

      @media only screen and (max-width: @medium), only screen and (max-device-width: @medium) {
        padding: @gap*2 @gap*3;
        max-width: 380px;
        margin-left: auto;
        margin-right: auto;
      }

      &__description {
        flex: 0 1 260px;

        @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
          display: none;

        }

        &__title {
          > * {
            font-size: @headname-4;
            font-weight: @fw-bold;
          }
        }

        &__text {
          > * {
            font-size: @caption;
            color: @color-b2;
            line-height: 120%;
          }
        }
      }

      form {
        flex: 1 1 auto;
        display: flex !important;
        flex-direction: row;
        padding-bottom: 0;
        flex-wrap: nowrap;

        .input-item:first-of-type {
          flex: 1 1 auto;
        }

        @media only screen and (max-width: @medium), only screen and (max-device-width: @medium) {
          flex-direction: column;
          gap: @gap;

          .input-item {
            width: 100%;

            > * {
              width: 100%;
            }

          }
        }
      }

      &__icon {
        position: absolute;
        top: 0;
        left: @gap;
        transform: translateY(-50%);
        transform-origin: 0 100%;
        animation: jump 2s ease-out infinite;

        @media only screen and (max-width: @medium), only screen and (max-device-width: @medium) {
          left: calc(-@gap * 3);
          top: @gap;
        }

        svg {
          width: 60px;
          height: 60px;
          fill: @color-primary;
        }
      }
    }

  }

  &__form {
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 380px;
    padding: calc(@gap * 3);
    .block-style-css;

    form {
      padding-bottom: 0;


    }

    .verificate-btn {
      margin-left: auto;
    }

  }

  .wave {
    position: absolute;
    bottom: -1px;
    z-index: 1;
    left: calc(50% - 1px);
    display: block;
    transform: translate(-50%, 0);
    width: calc(100% + 2px);
    min-width: 1024px;
    height: 25%;
    fill: @color-w;

    @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
      height: 120px;
    }


    path {
      width: 100%;
      height: 100%;
    }
  }
}

.main-services {
  position: relative;
  z-index: 5;
  display: flex;
  gap: calc(@gap * 3);

  @media only screen and (max-width: @width-content-limit), only screen and (max-device-width: @width-content-limit) {
    gap: calc(@gap * 2);
  }

  @media only screen and (max-width: 720px), only screen and (max-device-width: 720px) {
    width: 100%;
    flex-direction: column;
    gap: @gap;
  }


  &__item {
    display: flex;
    padding: calc(@gap * 3);
    flex-direction: column;
    justify-content: flex-start; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    background: @color-w;
    border: 1px solid @color-b6;
    border-radius: @br;
    box-shadow: @shadow-light;
    flex: 1 1 calc(33.33% - calc(@gap * 1.5));

    @media only screen and (max-width: @width-content-limit), only screen and (max-device-width: @width-content-limit) {
      padding: calc(@gap * 2);
      gap: calc(@gap * 2);
    }
    @media only screen and (max-width: 720px), only screen and (max-device-width: 720px) {
      flex-direction: row;
      justify-content: center; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
      padding: @gap calc(@gap * 2);
    }

    &__image {
      margin-bottom: @gap;

      svg, img {
        width: 154px;
        height: calc(154px / 1.45);

        @media only screen and (max-width: @width-content-limit), only screen and (max-device-width: @width-content-limit) {
          width: 120px;
          height: calc(120px / 1.45);
        }
        @media only screen and (max-width: 720px), only screen and (max-device-width: 720px) {
          width: 80px;
          height: calc(80px / 1.45);
        }
        @media only screen and (max-width: 420px), only screen and (max-device-width: 420px) {
          width: 60px;
          height: calc(60px / 1.45);
        }
      }
    }

    &__description {
      text-align: center;
      font-size: @paragraph-2;
      line-height: 135%;
      margin-bottom: calc(@gap * 2);


      h2 {
        display: none;
        width: 100%;
        font-weight: @fw-s-bold;
        font-size: @headname-4;
        line-height: 120%;
        tab-index: left;
      }

      @media only screen and (max-width: 720px), only screen and (max-device-width: 720px) {
        text-align: left;
        margin-bottom: 0;
        flex: 1 1 auto;

        h2 {
          display: flex;

        }

        p {
          display: none;
        }
      }

      @media only screen and (max-width: 540px), only screen and (max-device-width: 540px) {
        h2 {
          font-size: @headname-5;
        }
      }
      @media only screen and (max-width: 420px), only screen and (max-device-width: 420px) {
        h2 {
          font-size: @headname-6;
        }
      }
    }

    &__button {
      display: flex;
      width: 100%;
      margin-top: auto;

      @media only screen and (max-width: 720px), only screen and (max-device-width: 720px) {
        width: auto;
        margin-top: 0;
      }

      > * {
        flex: 1 1 auto;

        @media only screen and (max-width: 720px), only screen and (max-device-width: 720px) {
          min-width: auto !important;
          width: 50px !important;
          max-width: 50px;
          height: 50px !important;
          border-radius: 25px;
          padding: 0 !important;
          flex: 0 0 auto;

          span {
            display: none;
          }

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
}

@keyframes jump {
  0%, 100% {
    transform: translateY(-50%) scaleY(1.0);
  }
  45% {
    transform: translateY(-40%) scaleY(0.98);
  }
  50% {
    transform: translateY(-40%) scaleY(0.95);
  }
  56% {
    transform: translateY(-40%) scaleY(0.98);
  }
}

body .landing-main-banner {
  @media (min-width: 960px) and (max-width: @width-max-limit) {
    padding-top: calc(@header-h + calc(@gap * 4));
  }
  @media (min-width: 280px) and (max-width: 959.98px) {
    padding-top: calc(@header-small-h + calc(@gap * 4));
  }
}


// section with snakes blocks
.snake-blocks {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid @color-b6;

  @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
    padding-top: calc(@gap * 3);
  }

  .section-title {
    padding-top: calc(@gap * 4);
  }


  &__section {
    display: flex;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    padding-top: calc(@gap * 8);
    padding-bottom: calc(@gap * 8);

    .resolution-wrap {
      max-width: @width-text-limit;
    }

    &__content {
      display: flex;
      justify-content: center; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
      gap: calc(@gap * 4);

      &-image {
        svg, img {
          width: 280px;
          min-width: 280px;

          @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
            width: 260px;
            min-width: 260px;
          }
        }
      }

      &-description {
        .description-title {
          padding-bottom: @gap;

          > * {
            font: @fw-s-bold @headname-3/110% @font-primary;

            @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
              font-size: @headname-4;
            }
          }
        }

        .description-text {
          padding-bottom: calc(@gap * 2);

          p {
            font: @fw-reg @paragraph-1/140% @font-primary;

            @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
              font-size: @paragraph-2;
            }
          }
        }

        .description-btn {
          display: flex;
          justify-content: flex-start; // align X
          align-items: flex-start; // align Y
          align-content: flex-start; // align Y ios

          a {
            margin-right: auto;
          }

          @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
            width: 100%;
            justify-content: flex-end; // align X
            a {
              margin-right: 0;
            }
          }
        }
      }

      @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
        flex-direction: column;
        max-width: 480px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &__additional-blocks {

    }

    &:not(.section-title):nth-child(2n+1):not(:only-child) {
      background: @color-b6;

      .snake-blocks__section__content {
        flex-direction: row-reverse;

        @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
          flex-direction: column;
        }
      }
    }
  }
}

.additional-blocks {
  display: flex;
  min-width: 100%;
  flex: 1 1 auto;
  position: relative;
  padding-top: calc(@gap * 8);
  gap: calc(@gap * 4);
  overflow-y: hidden;
  overflow-x: auto;
  padding-bottom: @gap*4;

  &__item {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1 0 180px;
    gap: @gap;


    &:not(:last-child):not(:only-child) {
      &::after {
        content: '';
        width: 1px;
        height: 100%;
        position: absolute;
        right: calc(-@gap * 2);
        top: 0;
        background: @color-b5;
      }
    }

    h3, h4, h5 {
      font-size: @paragraph-2;
      font-weight: @fw-bold;
      line-height: 110%;
    }

    p {
      font-size: @caption;
      line-height: 110%;
      width: calc(100% - 50px);
    }

    a {
      display: flex;
      position: absolute;
      right: 0;
      bottom: 0;
      justify-content: center; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
      background: @color-primary;
      width: 40px;
      height: 40px;
      border-radius: 100%;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background: @color-hover;
        }
      }

      svg {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        fill: @color-w;
      }
    }
  }
}


// section with snakes blocks 2 (numeric)
.snake-blocks-2 {
  display: flex;
  flex-direction: column;
  padding-top: calc(@gap * 4);
  border-bottom: 1px solid @color-b6;
  counter-reset: num;

  @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
    padding-top: calc(@gap * 3);
  }


  &__section {
    display: flex;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    padding-top: calc(@gap * 8);
    padding-bottom: calc(@gap * 8);
    position: relative;

    &:nth-child(n+10) .resolution-wrap::before {
      content: counter(num);
    }

    .resolution-wrap {
      max-width: @width-text-limit;
      position: relative;
      @media only screen and (max-width: @medium), only screen and (max-device-width: @medium) {
        max-width: 420px;
      }

      &::before {
        position: absolute;
        content: '0' counter(num);
        counter-increment: num;
        width: 2ch; /* Забезпечує однакову ширину для всіх номерів */
        color: @color-primary;
        font-size: 234px;
        font-weight: @fw-h-bold;
        left: @gap*3;
        top: 40%;
        transform: translate(0, -100%);
        opacity: 0.2;

        @media only screen and (max-width: @medium), only screen and (max-device-width: @medium) {
          font-size: 160px;
        }

      }
    }

    &__content {
      display: flex;
      justify-content: center; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
      gap: calc(@gap * 4);
      width: calc(100% - 180px);
      margin-left: auto;

      @media only screen and (max-width: @medium), only screen and (max-device-width: @medium) {
        width: calc(100% - 72px);
      }

      &-description {

        .description-title {
          padding-bottom: @gap;
          position: relative;
          padding-top: @gap;

          &::before {
            content: '';
            width: 70px;
            height: 3px;
            background: @color-primary;
            position: absolute;
            top: -3px;

          }

          h3 {
            font: @fw-s-bold @headname-3/110% @font-primary;

            @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
              font-size: @headname-4;
            }
          }
        }

        .description-text {
          padding-bottom: calc(@gap * 2);

          p {
            font: @fw-reg @paragraph-1/140% @font-primary;

            @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
              font-size: @paragraph-2;
            }
          }
        }

        .description-btn {
          display: flex;
          justify-content: flex-start; // align X
          align-items: flex-start; // align Y
          align-content: flex-start; // align Y ios

          a {
            margin-right: auto;
          }
        }
      }

    }

    &:nth-child(2n + 1) {
      .resolution-wrap {
        &::before {
          left: auto;
          right: @gap*4;
          transform: translate(0, -100%);
        }
      }

      .snake-blocks-2__section__content {
        margin-right: auto;
        margin-left: 0;
      }

    }
  }
}


// section with snakes blocks 4 (steps with images)
.snake-blocks-4 {
  display: flex;
  flex-direction: column;
  padding-top: calc(@gap * 8);
  padding-bottom: calc(@gap * 8);
  border-bottom: 1px solid @color-b5;
  background: @color-b1;
  color: @color-w;

  .about-steps {
    display: flex;
    max-width: @xlarge;
    margin-left: auto;
    margin-right: auto;
    gap: @gap*3;
    padding-top: @gap*3;

    @media only screen and (max-width: 960px), only screen and (max-device-width: 960px) {
      flex-direction: column;
      padding-left: @gap;
      padding-right: @gap;
    }

    &__item {
      display: flex;
      flex-direction: column;
      gap: @gap*2;
      max-width: 300px;
      text-align: center;
      justify-content: flex-start; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
      position: relative;

      @media only screen and (max-width: 960px), only screen and (max-device-width: 960px) {
        flex-direction: row;
        max-width: 460px;
        text-align: left;

      }
      @media only screen and (max-width: 640px), only screen and (max-device-width: 640px) {
        gap: @gap;
      }


      &:first-child, &:last-child {
        &::before {
          content: '';
          display: block;

          width: calc(100% - 180px + @gap * 3);
          height: 1px;
          border-bottom: 2px dashed @color-b2;
          position: absolute;
          z-index: 1;
          top: 90px;

          @media only screen and (max-width: 960px), only screen and (max-device-width: 960px) {
            display: none;
          }

        }
      }

      &:first-child {
        &::before {
          left: 100%;
          transform: translateX(calc(-50% + @gap * 1.5));
        }
      }

      &:last-child {
        &::before {
          right: 100%;
          transform: translateX(calc(50% - @gap * 1.5));
        }
      }

      &__image {
        display: flex;
        justify-content: center; // align X
        align-items: center; // align Y
        align-content: center; // align Y ios
        width: 180px;
        height: 180px;
        padding: 24px;
        background: rgba(@color-w, 0.1);
        border-radius: 100%;
        box-shadow: @shadow-large;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          font-family: 'object-fit: cover;';
        }

        @media only screen and (max-width: 960px),
        only screen and (max-device-width: 960px) {
          width: 120px;
          min-width: 130px;
          height: 130px;
          position: relative;
          top: -10px;
          padding: 0;
          background: none;
        }

      }

      &__content {
        display: flex;
        flex-direction: column;
        gap: @gap*2;
        
        @media only screen and (max-width: 960px),
        only screen and (max-device-width: 960px) {
          gap: @gap / 2;
        }
             

        .description-title {
          h3 {
            font: @fw-s-bold @headname-4/110% @font-primary;

            @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
              font-size: @headname-5;
            }
          }
        }

        .description-text {
          padding-bottom: calc(@gap * 2);
          opacity: 0.6;

          p {
            font: @fw-reg @paragraph-1/140% @font-primary;

            @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
              font-size: @paragraph-2;
            }
          }
        }
      }
    }
  }


}

// section with snakes blocks 3 ( big images)
.snake-blocks-3 {
  display: flex;
  flex-direction: column;
  padding-top: calc(@gap * 4);
  border-bottom: 1px solid @color-b6;

  @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
    padding-top: calc(@gap * 3);
  }


  &__section {
    display: flex;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    padding-top: calc(@gap * 8);
    padding-bottom: calc(@gap * 8);

    .resolution-wrap {
      max-width: @width-content-limit;
    }

    &__content {
      display: flex;
      justify-content: center; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
      gap: calc(@gap * 4);

      &-image {
        svg, img {
          width: 380px;
          min-width: 380px;

          @media only screen and (max-width: 860px), only screen and (max-device-width: 860px) {
            width: 320px;
            min-width: 320px;
          }

          @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
            width: 260px;
            min-width: 260px;
          }
        }
      }

      &-description {
        .description-title {
          padding-bottom: @gap;

          > * {
            font: @fw-s-bold @headname-3/110% @font-primary;

            @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
              font-size: @headname-4;
            }
          }
        }

        .description-text {
          padding-bottom: calc(@gap * 2);

          p {
            font: @fw-reg @paragraph-1/140% @font-primary;
            margin-bottom: @gap;

            @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
              font-size: @paragraph-2;
            }
          }
        }

        .description-btn {
          display: flex;
          justify-content: flex-start; // align X
          align-items: flex-start; // align Y
          align-content: flex-start; // align Y ios

          a {
            margin-right: auto;
          }

          @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
            width: 100%;
            justify-content: flex-end; // align X
            a {
              margin-right: 0;
            }
          }
        }
      }


      @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
        flex-direction: column;
        max-width: 480px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &:nth-child(2n + 1):not(:only-child) {
      background: @color-b6;

      .snake-blocks-3__section__content {
        flex-direction: row-reverse;

        @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
          flex-direction: column;
        }
      }
    }
  }
}

// section-theme-3 (3 icons and text)
.section-theme-3 {
  .gradient-bg__dark;
  padding-top: calc(@gap * 8);
  padding-bottom: calc(@gap * 8);
  color: @color-w;

  @media only screen and (max-width: @medium), only screen and (max-device-width: @medium) {
    padding-top: calc(@gap * 4);
    padding-bottom: calc(@gap * 4);

    .resolution-wrap {
      max-width: 380px;
    }
  }

  .columns-wrap {
    display: flex;
    gap: calc(@gap * 2);
    padding-top: calc(@gap * 3);
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios

    @media only screen and (max-width: @medium), only screen and (max-device-width: @medium) {
      flex-direction: column;
      gap: @gap*6;
    }


    &__item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
      gap: calc(@gap * 2);
      max-width: 320px;

      @media only screen and (max-width: @medium), only screen and (max-device-width: @medium) {
        flex-direction: row;
        justify-content: center; // align X
        width: auto;
        display: inline-flex;

        &:nth-child(2n+1) {
          margin-right: auto;
        }

        &:nth-child(2n+2) {
          margin-left: auto;

          .columns-wrap__item__icon {
            order: 3;
          }

          .columns-wrap__item__text {
            text-align: right;
          }
        }
      }


      &__icon {
        width: 80px;
        height: 80px;
        min-width: 80px;
        min-height: 80px;
        display: flex;
        justify-content: center; // align X
        align-items: center; // align Y
        align-content: center; // align Y ios
        background: @color-w;
        border-radius: 100%;

        svg {
          width: 48px;
          height: 48px;
          fill: @color-b2;
        }

        @media only screen and (max-width: @medium), only screen and (max-device-width: @medium) {
          width: 60px;
          height: 60px;
          min-width: 60px;
          min-height: 60px;

          svg {
            width: 32px;
            height: 32px;
            fill: @color-b2;
          }
        }
      }

      &__text {
        text-align: center;

        @media only screen and (max-width: @medium), only screen and (max-device-width: @medium) {
          text-align: left;
          display: inline-flex;
        }
      }
    }

  }
}

// section-theme-9 (3 icons and text)
.section-theme-9 {
  background: @color-w;
  padding-top: calc(@gap * 8);
  padding-bottom: calc(@gap * 8);

  @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
    padding-top: calc(@gap * 4);
    padding-bottom: calc(@gap * 4);

    .resolution-wrap {
      max-width: 380px;
    }
  }

  .columns-wrap {
    display: flex;
    gap: calc(@gap * 2);
    padding-top: calc(@gap * 3);
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios

    @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
      flex-direction: column;
      gap: @gap*6;
    }


    &__item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
      gap: calc(@gap * 2);
      max-width: 320px;

      @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
        justify-content: center; // align X
        width: auto;
        display: inline-flex;
        text-align: center;

      }


      &__icon {
        width: 80px;
        height: 80px;
        min-width: 80px;
        min-height: 80px;
        display: flex;
        justify-content: center; // align X
        align-items: center; // align Y
        align-content: center; // align Y ios
        background: @color-w;
        border-radius: 100%;

        svg {
          width: 60px;
          height: auto;
        }

      }

      &__text {
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: @gap;

        @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
          display: inline-flex;
        }
      }
    }

  }
}

// section-theme-4 (Testimonial slider)
.section-theme-4 {
  padding-top: calc(@gap * 8);
  padding-bottom: calc(@gap * 8);
  background: @color-b6;

  @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
    padding-top: calc(@gap * 4);
    padding-bottom: calc(@gap * 4);
  }

  .slider-comments-type {

    &__item {
      min-width: 280px;
      width: calc(100% - 64px);
      max-width: 420px;

      .slide-wrap {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start; // align X
        align-items: center; // align Y
        align-content: center; // align Y ios
        padding: calc(@gap * 4) calc(@gap * 3) calc(@gap * 3);
        border-radius: @br;
        background: @color-w;
        box-shadow: @shadow-large;
        min-height: 180px;

        .slide-head {
          display: flex;
          width: 100%;
          justify-content: flex-start; // align X
          align-items: flex-start; // align Y
          align-content: flex-start; // align Y ios
          margin-bottom: @gap;

          .author-name {
            display: flex;
            white-space: nowrap;
            font-size: @headname-4;
            font-weight: @fw-s-bold;
          }

          time {
            text-align: right;
            white-space: nowrap;
            font-size: @caption;
            color: @color-b3;
            font-weight: @fw-reg;
            margin-left: auto;
            position: relative;
            top: 2px;
          }
        }

        .comment-text {
          font: @fw-reg @paragraph-2/140% @font-primary;


          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          -webkit-line-clamp: 4;
          line-height: 1.5; /* Висота рядків */
          max-height: calc(1.5em * 4); /* Забезпечення обмеження висоти */
        }
      }
    }
  }

}

// section-theme-5 (Prices)
.section-theme-5 {
  padding-top: calc(@gap * 8);
  padding-bottom: calc(@gap * 8);
  background: @color-b1;

  @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
    padding-top: calc(@gap * 4);
    padding-bottom: calc(@gap * 4);
  }

  .section-title {
    color: @color-w;
    display: none;
  }

  .prices-preview {
    display: flex;
    gap: calc(@gap * 3);
    //padding-top: calc(@gap*2);
    overflow: hidden;
    overflow-x: auto;
    scroll-behavior: smooth; /* Плавна прокрутка */
    scroll-snap-type: x mandatory; /* Прив'язка до елементів при свайпі */

    @media only screen and (max-width: 840px), only screen and (max-device-width: 840px) {
      width: calc(100% + @gap * 6);
      position: relative;
      left: -@gap*3;
      padding-left: @gap*3;
      padding-right: @gap*3;

      /* Плавний ефект при утриманні курсора */
      &:active {
        cursor: grab !important;
      }

      &:hover {
        cursor: grab !important;
      }

      &:active {
        cursor: grabbing !important;
      }
    }

    /* Приховуємо скролбар */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */

    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Edge */
    }

    &__item {
      border-radius: @br*3;
      background: @color-w;
      gap: @gap;
      display: flex;
      flex-direction: column;
      padding: calc(@gap * 3);
      //box-shadow: 4px 16px 60px rgba(@color-hover, 0.3);
      flex: 1 0 240px;
      user-select: none;

      @media only screen and (max-width: 596px), only screen and (max-device-width: 596px) {
        flex: 1 0 320px;
      }
      @media only screen and (max-width: 440px), only screen and (max-device-width: 440px) {
        flex: 1 0 280px;
      }
      @media only screen and (max-width: 380px), only screen and (max-device-width: 380px) {
        flex: 1 0 240px;
      }

      &__title {

        > * {
          font-size: @headname-4;
          font-weight: @fw-s-bold;
        }
      }

      &__description {
        margin-bottom: @gap;
        padding-bottom: calc(@gap * 2);
        position: relative;

        &::after {
          content: '';
          position: absolute;
          bottom: -1px;
          left: 0;
          background: @color-primary;
          display: block;
          width: 70px;
          height: 2px;

        }

        > * {
          font-size: @paragraph-1;
          font-weight: @fw-reg;
          color: @color-b2;
        }
      }

      &__price {
        margin-top: auto;
        text-align: left;

        > span {
          font-size: @paragraph-1;
        }

        &-number {
          margin-bottom: @gap;
          display: flex;
          min-height: 50px;
          justify-content: flex-start; // align X
          align-items: flex-end; // align Y
          align-content: flex-end; // align Y ios

          span {
            position: relative;
            font-size: 44px;
            color: @color-b2;
            font-weight: @fw-bold;
          }

          b {
            font-size: 60px;
            line-height: 110%;
            font-weight: @fw-bold;
            color: @color-b1;
          }
        }

      }

    }
  }


}

// section-theme-fees
.section-theme-fees {
  padding-top: calc(@gap * 8);
  padding-bottom: calc(@gap * 8);
  background: @color-w;

  .resolution-wrap {
    max-width: 1248px;
  }

  @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
    padding-top: calc(@gap * 4);
    padding-bottom: calc(@gap * 4);
  }


  .prices-preview {
    display: flex;
    // gap: calc(@gap * 3);
    flex-wrap: wrap;
    padding-top: @gap*4;
    //padding-top: calc(@gap*2);
    //overflow: hidden;
    //overflow-x: auto;
    //scroll-behavior: smooth; /* Плавна прокрутка */
    //scroll-snap-type: x mandatory; /* Прив'язка до елементів при свайпі */


    /* Приховуємо скролбар */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    margin-bottom: @gap*4;

    @media only screen and (max-width: 1024px), only screen and (max-device-width: 1024px) {
      justify-content: center; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios

    }

    &__item {
      gap: @gap*3;
      padding: @gap*3 @gap*2 @gap*3 @gap*2;
      display: flex;
      background: @color-w;
      width: calc(50% - calc(@gap * 3));
      user-select: none;

      &:nth-child(odd):not(:last-child) {
        border-right: 1px solid @color-b5;
      }

      // Всі блоки, крім останніх двох
      &:not(:nth-last-child(-n+2)) {
        border-bottom: 1px solid @color-b5;
      }


      @media only screen and (max-width: 1024px), only screen and (max-device-width: 1024px) {
        width: 100%;
        max-width: 650px;
        gap: @gap*2;
        padding: 0;

        &:not(:last-child) {
          padding: 0 0 @gap*3;
        }

        &:nth-child(odd):not(:last-child) {
          border-right: none;
        }

        // Всі блоки, крім останніх двох
        &:not(:nth-last-child(-n+2)) {
          border-bottom: none;
        }
      }


      &__image {
        top: -24px;
        position: relative;
        min-width: 180px;
        width: 180px;
        height: 180px;

        @media only screen and (max-width: 1240px), only screen and (max-device-width: 1240px) {
          min-width: 120px;
          width: 120px;
          height: 120px;
        }

        @media only screen and (max-width: 1024px), only screen and (max-device-width: 1024px) {
          min-width: 180px;
          width: 180px;
          height: 180px;
        }

        @media only screen and (max-width: 640px), only screen and (max-device-width: 640px) {
          min-width: 110px;
          width: 110px;
          height: 110px;
        }

        @media only screen and (max-width: 480px), only screen and (max-device-width: 480px) {
          min-width: 90px;
          width: 90px;
          height: 90px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          font-family: 'object-fit: cover;';
        }
      }


      &__description {
        margin-bottom: @gap;
        padding-bottom: calc(@gap * 2);
        position: relative;


        /*
        &::after {
          content: '';
          position: absolute;
          bottom: -1px;
          left: 0;
          background: @color-primary;
          display: block;
          width: 70px;
          height: 2px;

        }
*/

        > * {
          font-size: @paragraph-1;
          font-weight: @fw-reg;
          color: @color-b1;
        }

        > h3 {
          font-size: @headname-4 !important;
          font-weight: @fw-s-bold;
          margin-bottom: @gap*2;

          @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
            font-size: @headname-5 !important;
          }

        }

        ul {
          margin-bottom: 4px !important;

          li {
            min-width: 100%;
            margin-bottom: 4px !important;

            p {
              @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
                font-size: 14px;
              }
            }

            strong {
              font-size: 18px;
              @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
                font-size: 16px;
              }
            }

          }
        }
      }
    }
  }
}

// section-theme-6 (Adress list)
.section-theme-6 {
  padding-top: calc(@gap * 8);
  padding-bottom: calc(@gap * 8);
  background: @color-b6;

  &.bg-w {
    background: @color-w;
  }

  @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
    padding-top: calc(@gap * 4);
    padding-bottom: calc(@gap * 4);
  }

  .view-more-btn {
    padding-top: calc(@gap * 4);
    display: flex;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
  }
}

.section-theme-7 {
  padding-top: calc(@gap * 8);
  padding-bottom: calc(@gap * 8);
  background: @color-w;
  border-bottom: 1px solid @color-b6;

  @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
    padding-top: calc(@gap * 4);
    padding-bottom: calc(@gap * 4);
  }

  .accordion {
    padding-top: calc(@gap * 2);

  }
}

// section-theme-8 (input email Join Us)
.section-theme-8 {
  .gradient-bg__dark;
  padding-top: calc(@gap * 8);
  padding-bottom: calc(@gap * 8);
  color: @color-w;
  overflow: hidden;
  position: relative;

  .resolution-wrap {
    max-width: @width-popup-limit;
    position: relative;
    z-index: 3;
  }

  @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
    padding-top: calc(@gap * 4);
    padding-bottom: calc(@gap * 4);

    .resolution-wrap {
      max-width: 380px;
    }
  }

  .pre-title {
    opacity: 0.5;
    text-align: center;
    position: relative;
    top: 3px;
  }

  .input-item-group {
    display: flex;
    gap: 0;

    > .input-item {
      input {
        border-radius: @br 0 0 @br !important;
      }

      .btn-big {
        border-radius: 0 @br @br 0 !important;
      }
    }

    > .input-item:first-child {
      width: 100%;
    }
  }

  .logo-bg-decor {
    position: absolute;
    pointer-events: none;
    width: 520px;
    height: 520px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.07;
    z-index: 1;

    svg {
      width: inherit;
      height: inherit;
    }

    @media only screen and (max-width: @xxlarge), only screen and (max-device-width: @xxlarge) {
      top: 50%;
      right: 50%;
      transform: translate(-50%, -50%);
    }

  }

  .app-links {
    display: flex;
    gap: calc(@gap * 2);
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios

    a {
      display: flex;
      width: 120px;
      height: 40px;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          transform: scale(1.05);
        }
      }

      img {
        width: inherit;
        height: inherit;
      }
    }
  }

}

// section-theme-10 (fact list)
.section-theme-10 {
  .gradient-bg__dark;
  padding-top: calc(@gap * 8);
  padding-bottom: calc(@gap * 8);
  color: @color-w;
  overflow: hidden;
  position: relative;

  .resolution-wrap {
    max-width: @width-content-limit;
    position: relative;
    z-index: 3;
  }

  .columns-wrap {
    display: flex;
    gap: @gap*4;

    @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
      flex-wrap: wrap;
      justify-content: center; // align X

    }


    &__item {

      @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
        width: 100%;
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;

        text-align: center;
        justify-content: center; // align X
        align-items: center; // align Y
        align-content: center; // align Y ios
        &__number {

          &::after {
            left: 50%;
            transform: translateX(-50%);
          }
        }

      }

      &__number {
        font-size: 70px;
        line-height: 120%;
        font-weight: @fw-h-bold;
        position: relative;
        margin-bottom: @gap*2;

        &::after {
          content: '';
          width: 60px;
          height: 4px;
          background: @color-primary;
          position: absolute;
          left: 0;
          bottom: 0;
        }

      }

      &__text {
        font: @fw-reg @paragraph-2/120% @font-primary;
        color: @color-b3;
      }
    }
  }

  .logo-bg-decor {
    position: absolute;
    pointer-events: none;
    width: 520px;
    height: 520px;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    opacity: 0.07;
    z-index: 1;

    svg {
      width: inherit;
      height: inherit;
    }


  }


}

// location list item
.locations-list {
  padding-top: calc(@gap * 2);
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; // align X
  align-items: flex-start; // align Y
  align-content: flex-start; // align Y ios
  gap: calc(@gap * 1.5);

  @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
    gap: @gap;

    &__item {
      flex: 0 1 calc(33.33% - @gap);
    }
  }

  @media only screen and (max-width: @medium), only screen and (max-device-width: @medium) {
    row-gap: 2px;
  }

  &__item {
    display: flex;
    justify-content: flex-start; // align X
    align-items: flex-start; // align Y
    align-content: flex-start; // align Y ios
    gap: @gap;
    padding: calc(@gap * 2) calc(@gap / 2) calc(@gap * 2) calc(@gap * 1.5);
    background: @color-w;
    border-radius: @br;
    border: 1px solid @color-b6;
    box-shadow: @shadow-light;
    flex: 0 1 calc(33.33% - @gap);
    max-width: calc(33.33% - @gap);
    text-decoration: none;
    .anim_on(@anim);

    @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
      flex: 0 1 100%;
      max-width: calc(50% - @gap);
    }

    @media only screen and (max-width: @medium), only screen and (max-device-width: @medium) {
      max-width: 100%;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        transform: scale(1.05);
        border-color: @color-primary;
        z-index: 2;
      }
    }

    &__name {
      position: relative;
      top: 6px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      line-height: 1.5; /* Висота рядків */
      max-height: calc(1.5em * 1); /* Забезпечення обмеження висоти */

      > * {
        font: @fw-s-bold @headname-6/110% @font-primary;
        color: @color-b1;

        white-space: nowrap; /* Забороняє перенос тексту */
        overflow: hidden; /* Приховує те, що виходить за межі */
        text-overflow: ellipsis; /* Додає три крапки, якщо текст не поміщається */
        max-width: 100%; /* Не виходить за межі контейнера */


      }
    }

    &__icon, &__arrow {
      position: relative;
      top: 3px;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    &__icon {

      svg {
        fill: @color-primary;
      }
    }

    &__arrow {
      top: 2px;
      margin-left: auto;

      svg {
        fill: @color-b4;
      }
    }
  }
}

.content-with-sidebar__sidebar .locations-list {
  flex-direction: column;
  row-gap: 4px;
  padding-top: 0 !important;

  &__item {
    width: 100%;
    flex: 1 1 100% !important;
    padding: @gap calc(@gap / 2) @gap @gap;
    max-width: none;

    &__name {
      width: 100%;
      top: 8px !important;
      display: flex;

      > * {

        font: @fw-reg @paragraph-2/110% @font-primary;
        color: @color-b1;
        display: flex;
      }
    }
  }
}

// form example
.form-example {
  padding-top: calc(@gap * 8);
  padding-bottom: calc(@gap * 8);
}

// google-maps
.location-map-container {
  background: @color-b6;
  margin-bottom: @gap*4;
  border-radius: @br*2;
  overflow: hidden;

  .location-on-google-map {
    width: 100%;
    min-height: 370px;
  }
}

// slider on text page
.simple-slider {
  position: relative;
  margin-bottom: calc(@gap * 4);

  .slick-slide {
    overflow: hidden;
    border-radius: @br;

    img {
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;';
    }
  }

}

// sidebar-services
.sidebar-services {
  display: flex;
  width: 100%;
  text-decoration: none;
  background: @color-w;
  border: 1px solid @color-b5;
  border-radius: @br;
  box-shadow: @shadow-light;
  flex-direction: row;
  justify-content: flex-start; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios
  padding: calc(@gap * 2) @gap;
  gap: @gap;
  margin-bottom: @gap;
  .anim_on(@anim);

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      transform: translateY(-1px) scale(1.05);

      .sidebar-services__icon {
        background: @color-hover;

        svg {
          fill: @color-w;
        }
      }
    }
  }

  &__image {

    svg, img {
      width: 50px;
      height: calc(50px / 1.45);
    }
  }

  &__description {
    display: flex;
    justify-content: flex-start; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    font-size: @paragraph-2;
    flex: 1 1 auto;


    h2 {
      font-size: @headname-6;
      width: 100%;
      font-weight: @fw-s-bold;
      line-height: 100%;
      text-align: left;
      color: @color-b1;
    }

  }

  &__icon {
    min-width: auto !important;
    width: 32px !important;
    max-width: 32px;
    height: 32px !important;
    min-height: 32px;
    border-radius: 25px;
    padding: 0 !important;
    flex: 0 0 auto;
    background: @color-b6;
    display: flex;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    .anim_on(@anim);

    svg {
      width: 24px;
      height: 24px;
      position: relative;
      fill: @color-b3;
      .anim_on(@anim);
    }

  }
}

// blog section
.blog-list-section {
  padding-bottom: @gap*6;

  &__filters {
    form {
      flex-direction: row;
    }
  }
}

// partnership section
.partnership {
  display: flex;
  flex-wrap: wrap;

  &__item {
    flex: 1 0 420px;
    width: 50%;
    padding-top: calc(@gap * 4);
    padding-bottom: calc(@gap * 4);
    justify-content: center; // align X
    align-items: flex-start; // align Y
    align-content: flex-start; // align Y ios

    @media only screen and (max-width: 420px), only screen and (max-device-width: 420px) {
      flex: 1 1 280px;
    }


    &__content {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-self: center;
      max-width: 420px;
      padding-left: calc(@gap * 3);
      padding-right: calc(@gap * 3);

      // personal styles
      .pre-header {
        color: @color-b3;
      }


    }

    &.left-side {
      background: @color-w;
      color: @color-b1;
    }

    &.right-side {
      background: @color-b1;
      background: linear-gradient(277deg, @color-b1 0%, @color-b2 100%);
      color: @color-w;

      // personal styles
      a {
        color: @color-b5;

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            color: @color-hover;
          }
        }
      }

      a.link-with-icon {
        max-width: 100%;

        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          width: 100%; /* Фіксована ширина */
        }
      }
    }
  }
}

.help-icon-btn, .copy-icon-btn {
  display: inline-flex;
  position: relative;
  top: 6px;
  width: 24px;
  height: 24px;
  z-index: 2;
  justify-content: center; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      svg {
        fill: @color-hover;
      }
    }
  }

  svg {
    fill: @color-b4;
    width: 24px;
    height: 24px;
    .anim_on(@anim);
  }

}

.link-with-icon {
  display: inline-flex;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      svg {
        fill: @color-hover;
      }
    }
  }

  svg {
    fill: @color-b4;
    width: 24px;
    height: 24px;
    .anim_on(@anim);
  }
}


// section contacn + form
.section-contact {
  padding-top: calc(@gap * 8);
  padding-bottom: calc(@gap * 8);
  background: @color-b6;
  overflow: hidden;

  .resolution-wrap {
    position: relative;

    .logo-bg-decor {
      position: absolute;
      pointer-events: none;
      width: 380px;
      height: 380px;
      bottom: calc(-@gap * 8);
      left: 0;
      transform: translate(-20%, 0);
      opacity: 0.07;
      z-index: 1;

      svg {
        width: inherit;
        height: inherit;
      }

      @media only screen and (max-width: @xxlarge), only screen and (max-device-width: @xxlarge) {
        //top: 50%;
        //right: 50%;
        //transform: translate(-50%, -50%);
      }

    }

    > * {
      position: relative;
      z-index: 2;
    }
  }

  @media only screen and (max-width: @width-content-limit), only screen and (max-device-width: @width-content-limit) {
    .resolution-wrap {
      max-width: 420px;
    }
  }

  .contact-section {
    display: flex;
    justify-content: center; // align X
    align-items: flex-start; // align Y
    align-content: flex-start; // align Y ios
    gap: @gap*4;

    .section-title {
      display: flex;
      justify-content: flex-start; // align X
      align-items: flex-start; // align Y
      align-content: flex-start; // align Y ios

      > * {
        text-align: left;
      }
    }

    .section-sub-title {
      display: flex;
      justify-content: flex-start; // align X
      align-items: flex-start; // align Y
      align-content: flex-start; // align Y ios

      > * {
        text-align: left;
        font-size: @headname-4;
        font-weight: @fw-s-bold;

        @media only screen and (max-width: @width-content-limit), only screen and (max-device-width: @width-content-limit) {
          font-size: @headname-5;
        }
      }
    }

    @media only screen and (max-width: @width-content-limit), only screen and (max-device-width: @width-content-limit) {
      flex-wrap: wrap;
    }

    &__info {

      .contacts-block {
        display: flex;
        column-gap: @gap*3;
        flex-wrap: wrap;


        @media only screen and (max-width: @width-content-limit), only screen and (max-device-width: @width-content-limit) {
          flex-wrap: wrap;
        }

        a {
          padding: 8px 0;
          display: flex;

          gap: @gap;
          justify-content: flex-start; // align X
          align-items: flex-start; // align Y
          align-content: flex-start; // align Y ios
          white-space: nowrap;
          text-decoration: none;
          color: @color-b1;
          min-height: 24px;

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              color: @color-hover;
            }
          }

          svg {
            width: 24px;
            height: 24px;
            fill: @color-primary;
            position: relative;
            top: -2px;
          }
        }
      }

      &__text {
        margin-bottom: @gap;
      }

      @media only screen and (max-width: @width-content-limit), only screen and (max-device-width: @width-content-limit) {
        flex: 1 0 288px;
      }
    }

    &__form {
      flex: 1 0 466px;

      @media only screen and (max-width: @width-content-limit), only screen and (max-device-width: @width-content-limit) {
        flex: 1 0 288px;
        margin-left: auto;
        margin-right: auto;

      }

    }
  }
}

.hr-with-text {
  display: flex;
  justify-content: center; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios
  position: relative;
  width: 100%;
  min-height: @gap*12;
  gap: @gap*2;

  &::after, &::before {
    content: '';
    flex: 1 1 auto;
    height: 1px;
    background: @color-b5;
  }
}


// Sign In Lig In restore password pages
.section-profile-form {
  padding-top: @gap*8;
  padding-bottom: @gap*8;

  &__content {
    display: flex;
    gap: @gap*4;
    position: relative;

    &__image {
      width: 50%;
      display: flex;
      justify-content: center; // align X
      align-items: flex-start; // align Y
      align-content: flex-start; // align Y ios
      position: relative;
      z-index: 1;

      @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
        position: absolute;
        opacity: 0;
      }

      div.img-wrap {
        position: relative;
        display: flex;
        width: 100%;
        padding-bottom: 120.5%;
        transform: translateY(-7%);

        img {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          font-family: 'object-fit: cover;';
        }

      }
    }

    &__form {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start; // align X
      align-items: flex-start; // align Y
      align-content: flex-start; // align Y ios
      position: relative;
      z-index: 2;

      @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
        width: 100%;
        justify-content: center; // align X
        align-items: center; // align Y
        align-content: center; // align Y ios
        justify-self: stretch;
        align-self: stretch;

        .mw-form {
          display: block;
          padding: @gap*3;
          background: @color-w;
          border: 1px solid @color-b6;
          box-shadow: @shadow-large, 40px 30px 40px -4px rgba(0, 0, 0, 0.06);
          margin-left: auto;
          margin-right: auto;
          border-radius: @br;
        }
      }

      @media only screen and (max-width: 420px), only screen and (max-device-width: 420px) {
        .mw-form {
          padding: 0;
          border: none;
          box-shadow: none;
        }
      }

      .section-title, .section-sub-title {
        width: 100%;
        display: flex;
        justify-content: flex-start !important; // align X
        align-items: flex-start !important; // align Y
        align-content: flex-start !important; // align Y ios
        text-align: left;

        > * {
          width: 100%;
        }
      }

      a {
        color: @color-b1;

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            color: @color-hover;
          }
        }
      }

      .inputs-row {
        @media only screen and (max-width: @medium), only screen and (max-device-width: @medium) {
          //flex-direction: column;
          //justify-content: flex-start; // align X
          > * {
            //justify-content: flex-start !important; // align X
            // text-align: left !important;
          }
        }


        > * {
          flex: 1 1 auto;
          font-size: 12px;
        }

        .link-btn {
          justify-content: flex-end; // align X
          text-align: right;

          a {
            margin-left: auto;
            display: inline-flex;
            min-height: 24px;
            padding-top: 2px;
            margin-bottom: @gap;
          }
        }
      }
    }

    .down-info {
      font-size: 12px;
    }
  }
}

.inputs-row {
  display: flex;
  width: 100%;
  column-gap: @gap*2;
}


// Header search
.header-search {
  max-height: @small-elements-min-h;
  //border: 1px solid red;
  width: 100%;
  max-width: 320px;
  position: relative;
  z-index: 0;
  display: none;

  .form-theme {
    width: calc(100% - 40px);

    .input-item {
      position: relative;

      .input-mask-wrap, .placeholder-mask {
        min-height: @small-elements-min-h;
        max-height: @small-elements-min-h;
      }

      .placeholder-mask {
        font-size: 12px !important;
      }

      input {
        position: relative;
        border: 1px solid @color-b6;
        background: @color-b6;
        min-height: @small-elements-min-h;
        max-height: @small-elements-min-h;
        padding-right: 42px;
        z-index: 1;
        padding-top: 0;
        font-size: 14px;
        //border: 1px solid greenyellow;

        &:focus:placeholder-shown,
        &:not(:placeholder-shown) {
          font: @field-font;
          color: @field-text-color;
          min-height: @small-elements-min-h;
          max-height: @small-elements-min-h;
          font-size: 14px !important;
          line-height: 100% !important;
          border-color: @color-b3;

          & ~ .placeholder-mask {
            opacity: 0;
          }

        }
      }

      button {
        position: absolute;
        z-index: 2;
        top: 50%;
        transform: translateY(-50%);
        border: none;
        background: 0;
        outline: none;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center; // align X
        align-items: center; // align Y
        align-content: center; // align Y ios

        svg {
          width: 24px;
          height: 24px;
          fill: @color-b4;
          .anim_on(@anim);
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            svg {
              fill: @color-primary;
            }
          }
        }

        &.btn_search {
          right: 0;
        }

        &.btn_neighborhoods {
          right: 4px;

          &:hover {
            &::before {
              color: @color-primary;
            }
          }


          &::before {
            color: @color-b4;
            display: none !important;
          }
        }
      }
    }
  }

  &.neighborhoods-active {
    .neighborhoods_find_list {
      opacity: 1;
      pointer-events: all;
    }
  }

  &.search-active {
    .addresses_find_list {
      opacity: 1;
      pointer-events: all;
    }
  }

  .neighborhoods_find_list,
  .addresses_find_list {
    display: block;
    position: absolute;
    width: 100%;
    left: 0;
    top: 40px;
    .block-style-css;
    padding: 4px 4px 4px 0;

    .anim_on(@anim);
    opacity: 0;
    pointer-events: none;

    ul {
      display: flex;
      justify-content: flex-start; // align X
      align-items: flex-start; // align Y
      align-content: flex-start; // align Y ios
      flex-direction: column;
      height: auto;
      max-height: 420px;
      overflow: hidden;
      overflow-y: auto;

      li {
        width: 100%;
        display: block;

        &:not(:last-child) {
          border-bottom: 1px solid @color-b6;
        }

        a {
          display: flex;
          width: 100%;
          justify-content: flex-start; // align X
          align-items: center; // align Y
          align-content: center; // align Y ios
          padding: calc(@gap + 2px) @gap*2 @gap;
          text-decoration: none;
          color: @color-b1;
          font-size: @paragraph-2;


          @media (hover: hover) and (pointer: fine) {
            &:hover {
              color: @color-w;
              background: @color-hover;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 460px), only screen and (max-device-width: 460px) {
    .form-theme {
      .input-item {
        .input-mask-wrap.search-wrap {

          &::before {
            display: none;
          }

          .placeholder-mask {
            padding-left: @gap*2 !important;
          }

          input {
            padding-left: @gap*2 !important;
          }
        }

        button.btn_neighborhoods {
          right: 2px;
        }
      }
    }
  }

}


// MAP
.map-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: var(--app-height);
  background: @color-w;
  display: flex;
  flex-direction: column;
  z-index: 1;

  .map-container, .map_container {
    background: @color-b2;
    display: block;
    width: 100%;
    height: 100%;
  }

  @media (min-width: 960px) and (max-width: @width-max-limit) {
    padding-top: @header-h;
    padding-bottom: @footer-h;
  }
  @media (min-width: 280px) and (max-width: 959.98px) {
    padding-top: @header-small-h;
    padding-bottom: @footer-h;
  }
}

.street-cleaning-block__open-close {
  display: none;
  left: 10px;
  z-index: 8;

  @media (min-width: 960px) and (max-width: @width-max-limit) {
    top: 10px + @header-h;
  }

  @media (min-width: 280px) and (max-width: 959.98px) {
    top: 10px +  @header-small-h;
  }
}

.street-cleaning-block__open-close.active {
  @media (min-width: 960px) and (max-width: @width-max-limit) {
    top: 10px + @header-h + 4px;
  }

  @media (min-width: 280px) and (max-width: 959.98px) {
    top: 10px +  @header-small-h + 4px;
  }
}

@media only screen and (max-width: 859.9px), only screen and (max-device-width: 859.9px) {
  .street-cleaning-block__open-close {
    display: flex;
    background: @color-w;
    width: 40px;
    height: 40px;
    position: absolute;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    border-radius: 100%;
    box-shadow: @shadow-large;
    .anim_on(0.14s);
    cursor: pointer;

    @media (hover: hover) and (pointer: fine) {
      &:not(.active):hover {
        background: @color-hover;
        box-shadow: @primary-btn-hover;

        * {
          fill: @color-w !important;
        }
      }
    }

    * {
      fill: @color-b2 !important;
      width: 24px;
      height: 24px;
    }

    [class*='icon-open'] {
      display: block;
    }

    [class*='icon-close'] {
      display: none;
    }

    &.active {
      [class*='icon-open'] {
        display: none;
      }

      [class*='icon-close'] {
        display: block;
      }
    }
  }

  .street-cleaning-block__open-close.active {
    background: @color-b6;
    box-shadow: none;
    left: 250px;

    & ~ .left-block {
      max-width: 280px;
      max-height: none;
      border-radius: @br;
      opacity: 1;
      pointer-events: all;
      transform: scaleY(1);
    }
  }
}

.left-block {
  position: absolute;
  z-index: 3;
  left: 10px;
  background: @color-w;
  border-radius: @br;
  box-shadow: @shadow-light;
  width: 100%;
  max-width: 280px;

  .anim_on(0.14s);
  transform-origin: 0 0 !important;

  @media (min-width: 960px) and (max-width: @width-max-limit) {
    top: 10px + @header-h;
  }
  @media (min-width: 280px) and (max-width: 959.98px) {
    top: 10px +  @header-small-h;
  }

  &__head {
    background: @color-b6;
    border-width: 1px 1px 0 1px;
    border-color: @color-b5;
    border-radius: @br @br 0 0;
    display: flex;
    border-style: solid;
    gap: @gap;
    padding: 13px @gap*2 12px;
    min-height: @elements-min-h;

    > h1, > h2 {
      font-size: @paragraph-2;
      font-weight: @fw-s-bold;
      position: relative;
      top: 2px;
    }

    &__icon {

      width: 24px;
      min-width: 24px;
      height: 24px;

      > * {
        fill: @color-b2;
      }
    }

  }

  &__body {
    background: @color-w;
    border-width: 0 1px 1px 1px;
    border-color: @color-b5;
    border-radius: 0 0 @br @br;
    border-style: solid;

    .info {
      padding: @gap;
      font-size: @caption;
      margin-bottom: 0 !important;

    }


  }


  @media only screen and (max-width: 859.9px), only screen and (max-device-width: 859.9px) {
    max-width: 40px;
    max-height: 40px;
    border-radius: 25px;
    opacity: 0;
    pointer-events: none;
    transform: scaleY(0.1);
  }
}

.geolocation-btn {
  position: fixed;
  width: 40px;
  height: 40px;
  right: 6px;

  border-radius: 100%;
  box-shadow: @shadow-large;
  z-index: 99;
  display: flex;
  justify-content: center; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios
  background: @color-primary;
  .anim_on(@anim);

  @media (min-width: 960px) and (max-width: @width-max-limit) {
    bottom: 186px;
  }
  @media (min-width: 280px) and (max-width: 959.98px) {
    bottom: 186px;
  }

  svg {
    .anim_on(@anim);
    fill: @color-w;
    width: 16px;
    height: 16px;
    z-index: 3;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background: @color-hover;
      cursor: pointer;
      box-shadow: @primary-btn-hover;
    }
  }

}

.tabs {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__caption {
    display: flex;
    width: 100%;
    position: relative;
    justify-content: center; // align X
    align-items: stretch; // align Y
    align-content: stretch; // align Y ios
    padding-left: @gap;
    padding-right: @gap;

    &__item {

    }

  }

  // стилі табів якщо не активен жоден
  &:not(.inactive-tabs) {

    .tabs__caption {

      &::after, &::before {
        content: '';
        display: flex;
        bottom: 0;
        position: absolute;
        width: @gap;
        height: 100%;
        border-bottom: 1px solid @color-b5;
      }

      &::after {
        right: 0;
      }

      &::before {
        left: 0;
      }

      &__item {
        flex: 1 1 auto;
        width: auto;
        display: flex;
        gap: @gap / 2;
        padding: @gap*1.5 @gap;
        justify-content: center; // align X
        align-items: center; // align Y
        align-content: center; // align Y ios
        border-radius: @br @br 0 0;

        span {
          font-size: @caption;
          display: inline-flex;
          min-height: 16px;
        }

        &:not(.active) {
          background: @color-b6;
          border-bottom: 1px solid @color-b5;

          span {
            color: @color-b4;
          }

          svg {
            fill: @color-b4;
          }

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              cursor: pointer;

              span {
                color: @color-b3;
              }

              svg {
                fill: @color-b3;
              }
            }
          }
        }

        &.active {
          border-width: 1px 1px 0 1px;
          border-style: solid;
          border-color: @color-b5;

          svg {
            fill: @color-primary;
          }
        }

        .tab__icon {
          min-width: 16px;
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  // стилі табів якщо хоч один активний
  &.inactive-tabs {
    padding-bottom: @gap;

    .tabs__caption {
      gap: 2px;

      &__item {
        flex: 1 1 auto;
        width: auto;
        display: flex;
        gap: @gap / 2;
        padding: @gap*1.5 @gap;
        justify-content: center; // align X
        align-items: center; // align Y
        align-content: center; // align Y ios
        border-radius: @br;

        span {
          font-size: @caption;
          display: inline-flex;
          min-height: 16px;
        }

        &:not(.active) {
          background: @color-b6;

          span {
            color: @color-b4;
          }

          svg {
            fill: @color-b4;
          }

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              cursor: pointer;

              span {
                color: @color-b3;
              }

              svg {
                fill: @color-b3;
              }
            }
          }
        }

        &.active {
          border-width: 1px 1px 0 1px;
          border-style: solid;
          border-color: @color-b5;

          svg {
            fill: @color-primary;
          }
        }

        .tab__icon {
          min-width: 16px;
          width: 16px;
          height: 16px;
        }
      }
    }
  }


  &__content:not(.active) {
    display: none;
  }

  &__content.active {
    padding: @gap;
    display: flex;
    flex-direction: column;
    gap: @gap;
    overflow-x: hidden;
    overflow-y: auto;
    height: var(--app-height);

    @media (min-width: 960px) and (max-width: @width-max-limit) {
      max-height: calc(var(--app-height) - 282px);
    }
    @media (min-width: 280px) and (max-width: 959.98px) {
      max-height: calc(var(--app-height) - 262px);
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background: @color-b3;
    }

    &::-webkit-scrollbar-thumb {
      background: @scroll_thumb;
      border-radius: @br;

      &:hover {
        background: @color-primary;
      }

      &:active {
        background: @color-primary;
      }
    }


  }


}

.street-cleaning-form {
  padding: @gap / 1.5 !important;
  background: @color-b6;
  border-radius: @br;

  .inputs-row {
    gap: @gap !important;

    > * {
      flex: 1 1 50%;
    }
  }

  .input-mask-wrap {
    min-height: @small-elements-min-h;

    &.select-container::before {
      width: @small-elements-min-h;
      height: @small-elements-min-h;
      right: -4px;
    }

    select {
      padding-top: 2px;
      padding-left: @gap !important;
      padding-right: 18px;
      min-height: @small-elements-min-h;
      font-size: @caption !important;
    }
  }
}

.steps-group {
  display: flex;
  flex-direction: column;
  gap: @gap;


  &__item {
    display: flex;
    justify-content: flex-start; // align X
    align-items: flex-start; // align Y
    align-content: flex-start; // align Y ios
    min-height: 32px;
    color: @color-b2;
    line-height: 105%;
    gap: @gap;
    position: relative;

    &.hidden {
      display: none !important;
    }

    &:not(:only-child):not(:last-of-type) {
      &::before {
        content: '';
        position: absolute;
        left: 15px;
        width: 2px;
        height: calc(100% + @gap);
        background: @color-b5;
        z-index: 1;
      }
    }

    &__icon {
      display: flex;
      position: relative;
      z-index: 2;
      justify-content: center; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
      width: 32px;
      min-width: 32px;
      height: 32px;
      border-radius: 100%;
      border-style: solid;
      border-width: 1px;
      background: @color-w;

      &[data-color="gray"] {
        border-color: @color-b4;

        svg {
          > * {
            fill: @color-b4;
          }
        }
      }

      &[data-color="green"] {
        border-color: @color-secondary;

        svg {
          > * {
            fill: @color-secondary;
          }
        }
      }

      &[data-color="orange"] {
        border-color: @color-attention;

        svg {
          > * {
            fill: @color-attention;
          }
        }
      }

      &[data-color="red"] {
        border-color: @color-error;

        svg {
          > * {
            fill: @color-error;
          }
        }
      }

      svg {
        width: 24px;
        height: 24px;

      }
    }

    &__content {
      width: 100%;
      display: inline-flex;
      min-height: 32px;
      position: relative;
      flex-direction: column;

      .text {
        display: flex;
        width: 100%;
        justify-content: flex-start; // align X
        align-items: center; // align Y
        align-content: center; // align Y ios
        flex-wrap: wrap;
        min-height: 32px;
        padding: 0;
        gap: 4px;
        font-size: @caption;
        padding-right: 24px;


      }

      .help-btn {
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center; // align X
        align-items: center; // align Y
        align-content: center; // align Y ios
        position: absolute;
        top: 5px;
        right: 0;
        z-index: 2;

        &.active {
          z-index: 8;

          .help-tooltip {
            transform: scaleY(1) translateY(0);
            opacity: 1;
            pointer-events: all;
          }
        }

        svg {
          width: 24px;
          height: 24px;
          fill: @color-b4;
          .anim_on(@anim);
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            cursor: pointer;

            svg {
              fill: @color-hover;
            }
          }
        }

        .help-tooltip {
          position: absolute;
          width: 200px;
          top: 32px;
          right: -5px;
          font-size: 12px;
          line-height: 130%;
          border-radius: @br;
          padding: @gap;
          background: @color-w;
          border: 1px solid @color-b5;
          box-shadow: @shadow-large;
          transform-origin: 0 0;

          transition: all 0.1s linear;
          transform: scaleY(0.5) translateY(-10px);
          opacity: 0;
          pointer-events: none;

          &::after {
            content: '';
            width: 12px;
            height: 12px;
            border-radius: 4px 0 0 0;
            border-left: 1px solid @color-b5;
            border-top: 1px solid @color-b5;
            position: absolute;
            top: -6px;
            right: 10px;
            background: @color-w;
            transform: rotate(45deg);
          }
        }
      }

      .view-schedule-btn {
        display: flex;
        text-align: left;
        white-space: nowrap;
        justify-content: flex-start; // align X
        align-items: center; // align Y
        align-content: center; // align Y ios
        gap: 4px;

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            cursor: pointer;

            span {
              color: @color-hover;
            }

            svg {
              fill: @color-hover;
            }
          }
        }


        &__close {
          display: none;
        }

        &.active {
          .view-schedule-btn__view {
            display: none;
          }

          .view-schedule-btn__close {
            display: block;
          }

          svg:last-child {
            transform: scaleY(-1);
          }
        }

        span {
          color: @color-primary;
          font-size: 12px;
          line-height: 102%;
        }

        svg {
          width: 16px;
          height: 16px;
          min-width: 16px;

          fill: @color-primary;
        }
      }
    }


  }
}

.sc-login {
  display: flex;
  justify-content: center; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios
  min-height: 220px;
  text-align: center;

  > * {
    display: flex;
    flex-direction: column;
    gap: @gap;

    .btn-small {
      min-width: 220px;
    }
  }
}

.setup-alert {

  .alert_form {
    max-width: 220px;
    margin-left: auto;
    margin-right: auto;

    .user-data-label {
      font-size: 12px;
      white-space: nowrap; /* Забороняє перенесення тексту на новий рядок */
      overflow: hidden; /* Ховає текст, який виходить за межі контейнера */
      text-overflow: ellipsis; /* Додає "..." до кінця тексту */
      display: block; /* Підходить для блочних елементів */
      width: 100%; /* Ширина контейнера (можна змінити) */
      max-width: 220px;
    }

    .input-mask-wrap {
      min-height: @small-elements-min-h;

      &.select-container::before {
        width: @small-elements-min-h;
        height: @small-elements-min-h;
        right: -4px;
      }

      select {
        padding-top: 2px;
        padding-left: @gap !important;
        padding-right: 18px;
        min-height: @small-elements-min-h;
        font-size: @caption !important;
      }
    }

    .sc-form-footer {
      display: flex;
      flex-direction: column;
      justify-content: center; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
      gap: @gap;

      .btn-small {
        max-width: 220px;
        min-width: 220px;
      }

    }
  }

}


// CALENDAR
//===================================================================================
@cell-size: 26px;

.streetcleaning_calendar {
  z-index: 3;
  background: @color-w;
  display: none;
  width: 100%;
  height: auto;
  position: relative;
  left: 0;
  padding: 0;
  background: @color-w;
  margin-top: 8px;

  .sc_calendar_head {
    height: @cell-size;
    border-radius: @br;
    background: @color-b6;
    display: flex;
    justify-content: flex-start; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    gap: 8px;
    padding: 0 16px;

    & > * {
      display: inline-block;
      line-height: 102%;
      white-space: nowrap;
      overflow: hidden;
      font-size: 11px;
    }

    .sc_date {
      text-align: right;
    }

  }

  .sc_calendar_body {
    display: table;
    position: relative;
    width: 100%;
    height: auto;
    padding: 0;

    .sc_calendar_week_number {
      width: @cell-size - 4px;
      position: absolute;
      left: 0;
      border-right: 1px solid @color-b5;

      & > * {
        width: 100%;
        text-align: center;
        height: @cell-size;
        line-height: @cell-size;
        font-size: 12px;
      }
    }


    .sc_calendar {
      width: 100%;
      padding-left: @cell-size - 4px;

      .days, .days_numbers {
        display: flex;
        flex-wrap: wrap;

        & > * {
          display: inline-block;
          flex: 1 1 calc(100% / 7);
          height: @cell-size;
          justify-content: center; // align X
          align-items: center; // align Y
          align-content: center; // align Y ios
          text-align: center;
        }

      }

      .days {

        & > * {
          font-size: 12px;

          &:not(:last-child) {
            border_right: 1px solid @color-b4;
          }
        }

      }

      .days_numbers {
        & > * {
          display: flex;
          justify-content: center; // align X
          align-items: center; // align Y
          align-content: center; // align Y ios

          &:nth-child(7n+7) {
            border-right: none;
          }

          span {
            display: flex;
            justify-content: center; // align X
            align-items: center; // align Y
            align-content: center; // align Y ios
            width: 20px;
            height: 20px;
            line-height: 102%;
            border: 1px solid @color-b4;
            border-radius: 30px;
            font-size: 10px;
            text-align: center;
          }
        }

        .prev_month,
        .next_month {
          color: @color-b2;
        }

        .sc_today > span {
          background: @color-primary;
          color: @color-w;
        }

        .sc_date > sup {
          top: 0;
        }

        .sc_yes > span {
          border-color: @color-error !important;
          cursor: pointer;
        }

        .sc_yes.active span {
          box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.15);
        }
      }


    }
  }

  .sc_calendar_footer {
    height: @cell-size;
    border-radius: @br;
    background: @color-b6;
    display: flex;
    justify-content: flex-start; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    gap: 8px;
    padding: 0 16px;

    & > * {
      display: inline-block;
      line-height: 102%;
    }

    .sc_calendar_legend {
      padding-left: 25px;
      position: relative;
      font-size: 12px;

      &:before {
        content: "";
        display: block;
        width: 13px;
        height: 13px;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -6px;
        border: 1px solid @color-error;
        border-radius: 30px;
      }

    }
  }
}


.view-schedule-btn.active ~ .streetcleaning_calendar {
  display: block;
}


// google ads on map
.banner-on-map {
  position: absolute;
  display: block;
  right: 10px;
  z-index: 2;
  box-shadow: @shadow-light;
  .anim_on(@anim);
  opacity: 1;
  transform: translateX(0);

  &.hide {
    opacity: 0;
    transform: translateX(100%);
  }

  @media (min-width: 960px) and (max-width: @width-max-limit) {
    top: calc(@header-h + 10px);
  }
  @media (min-width: 280px) and (max-width: 959.98px) {
    top: calc(@header-small-h + 10px);
  }

  &__close-btn {
    display: flex;
    position: absolute;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    width: 24px;
    height: 24px;
    background: @color-b1;
    top: -6px;
    right: -6px;
    border-radius: 100%;
    z-index: 4;
    .anim_on(@anim);

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background: @color-primary;
        cursor: pointer;
      }
    }

    &::after {
      display: block;
      width: 16px;
      height: 16px;
      content: url('data:image/svg+xml;charset=utf-8,<svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.0078 10.9453L17.7266 5.22656C18.0807 4.9349 18.4245 4.9349 18.7578 5.22656C19.0703 5.58073 19.0703 5.9349 18.7578 6.28906L13.0703 12.0078L18.7578 17.7266C19.0703 18.0807 19.0703 18.4245 18.7578 18.7578C18.4245 19.0703 18.0807 19.0703 17.7266 18.7578L12.0078 13.0703L6.28906 18.7578C5.9349 19.0703 5.58073 19.0703 5.22656 18.7578C4.9349 18.4245 4.9349 18.0807 5.22656 17.7266L10.9453 12.0078L5.22656 6.28906C4.9349 5.9349 4.9349 5.58073 5.22656 5.22656C5.58073 4.9349 5.9349 4.9349 6.28906 5.22656L12.0078 10.9453Z" fill="rgb(255,255,255)"/></svg>');
    }
  }

  a {
    display: flex;
    width: 220px;
    max-width: inherit;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    overflow: hidden;
    height: calc(220px / 3.01);
    border-radius: @br;
    border: 1px solid transparent;
    //box-shadow: inset 0 0 0 2px rgba(@color-primary);

    img {
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;';
      .anim_on(@anim);
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        border: 1px solid @color-primary;

        img {
          transform: scale(1.05);
        }
      }
    }
  }


}


.search-on-map, .sc-alert-subscribe {
  .input-item-group {
    display: flex;
    gap: 0;

    > .input-item {
      input {
        border-radius: @br 0 0 @br !important;
      }

      .btn-big {
        border-radius: 0 @br @br 0 !important;

        svg {
          display: none;
        }

        @media only screen and (max-width: 580px), only screen and (max-device-width: 580px) {

          min-width: unset;

          svg {
            display: flex;
            width: 32px;
            height: 32px;
          }

          span {
            display: none;
          }
        }

      }
    }

    > .input-item:first-child {
      width: 100%;
    }
  }
}

.map-block-in-text-page {
  width: 100%;
  display: block;
  background: @color-b6;
  border-radius: @br;
  min-height: 280px;
  overflow: hidden;
  margin-bottom: @gap*4;
}

.form_signup {
  .input-item {
    button, input[type=submit], input[type=button] {
      width: 100%;
      justify-self: stretch !important;
    }
  }
}


// USER PREFERENCES
// -----------------------------------------------------------------------------------------

.section-user-preferences{
  text-align: center;

  padding: @gap*8 0;

  .text-box{
    margin-left: auto;
    margin-right: auto;
  }

  &__img{
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
    padding-top: @gap;
    padding-bottom: @gap;
  }

  .btns-group{
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
  }

}

.user-info-container {
  padding-bottom: @gap*3;

  &__form {
    display: none;
  }

  &__preview {

    .user-info-table {
      display: flex;
      flex-direction: column;
      gap: @gap;
      margin-bottom: @gap*3;

      &__item {
        display: flex;
        flex-direction: column;
        gap: @gap / 2;
        padding: 4px 0;


        div:first-child {
          color: @color-b3;
          min-width: 120px;
          font-size: 14px;
        }

        div:last-child {
          color: @color-b1;
          font-size: 20px;
        }
      }

    }


  }

  &.edit {
    .user-info-container__form {
      display: block;
    }

    .user-info-container__preview {
      display: none;
    }
  }
}


.btns-group {
  display: flex;
  flex-wrap: wrap;
  gap: @gap*2;
}


.pdng-btn-3 {
  padding-bottom: @gap*3;
}


.faq-list-container {
  .accordion {
    margin-bottom: @gap*4;
  }
}

.user-alerts-table {
  display: table;
  width: 100%;
  list-style: none !important;
  padding: @gap*2;
  background: @color-w;
  border: 1px solid @color-b5;
  box-shadow: @shadow-light;
  overflow: hidden;
  overflow-x: auto;
  border-radius: @br;

  li {
    display: table-row;
    margin: 0 !important;
    padding: 0 !important;
    min-height: auto !important;

    &:nth-child(2) {
      > * {
        padding-top: @gap * 3;
      }
    }

    &.user-alert-header {
      > * {
        border-bottom: 1px solid @color-b5;
        padding-bottom: @gap*2;
        font-weight: @fw-bold;
      }

    }

    &::before {
      content: '';
      display: none !important;
    }

    > * {
      display: table-cell;
      padding-top: @gap;
      padding-bottom: @gap;
      vertical-align: middle;
      font-size: 14px;

      &:not(:first-child):not(:last-child) {
        padding-left: @gap*2;
        padding-right: @gap*2;
      }

      &:first-child {
        padding-right: @gap*2;
      }

      &:last-child {
        padding-left: @gap*2;
      }

      .help-text-select {
        position: absolute;
        opacity: 0;
        visibility: hidden;
      }

      form {
        min-width: 90px;
      }

      .alert-del-btn {
        display: flex;
        background: none;
        outline: none;
        border: 0;
        min-width: 36px;
        margin: 0;
        padding: 0;
        width: 36px;
        height: 36px;
        justify-content: center; // align X
        align-items: center; // align Y
        align-content: center; // align Y ios

        &::after {
          content: url('data:image/svg+xml;charset=utf-8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.4688 7.4375C17.8698 7.47396 18.0885 7.69271 18.125 8.09375C18.0885 8.49479 17.8698 8.71354 17.4688 8.75H17.1406L16.4844 17.6367C16.4479 18.0924 16.2656 18.4753 15.9375 18.7852C15.6094 19.0768 15.2174 19.2318 14.7617 19.25H9.23828C8.78255 19.2318 8.39062 19.0768 8.0625 18.7852C7.73438 18.4753 7.55208 18.0924 7.51562 17.6367L6.85938 8.75H6.53125C6.13021 8.71354 5.91146 8.49479 5.875 8.09375C5.91146 7.69271 6.13021 7.47396 6.53125 7.4375H8.44531L9.45703 5.93359C9.76693 5.49609 10.1862 5.26823 10.7148 5.25H13.2852C13.8138 5.26823 14.2422 5.49609 14.5703 5.93359L15.5547 7.4375H17.4688ZM10.7148 6.5625C10.6237 6.5625 10.569 6.59896 10.5508 6.67188L10.0312 7.4375H13.9961L13.4766 6.67188C13.4219 6.59896 13.3581 6.5625 13.2852 6.5625H10.7148ZM15.8281 8.75H8.17188L8.82812 17.5273C8.86458 17.7826 9.0013 17.9193 9.23828 17.9375H14.7617C14.9987 17.9193 15.1445 17.7826 15.1992 17.5273L15.8281 8.75Z" fill="rgb(156 163 174)" /></svg>');

        }
      }
    }
  }
}


.please_zoom{
  position: absolute;
  z-index: 99;
  pointer-events: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(@color-w, 0.8);
  backdrop-filter: blur(4px);
  box-shadow: @shadow-light;
  border: 1px solid @color-b5;
  border-radius: @br;
  padding: @gap @gap*2;
  font-size: 12px;
}

// SITEMAP
.sitemap {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    list-style: none; /* Видаляємо маркери */
    padding: 8px 12px;
    position: relative;

    a {
      text-decoration: none;
      display: inline-block;
      transition: color 0.3s ease;

      &:visited {
        //color: #a03bff;
      }
    }

    ul {
      list-style: none; /* Видаляємо стандартні маркери */
      padding-left: 20px;
      margin: 5px 0 0;
      border-left: 1px solid @color-b5;
    }

    &::before {
      content: ""; /* Кастомний маркер */
      position: absolute;
      left: -12px;
      font-size: 16px;
    }
  }
}


.padding-top-x1 {
  padding-top: @gap;
}

.padding-bottom-x1 {
  padding-bottom: @gap;
}

.padding-top-x2 {
  padding-top: @gap*2;
}

.padding-bottom-x2 {
  padding-bottom: @gap*2;
}

.padding-top-x3 {
  padding-top: @gap*3;
}

.padding-bottom-x3 {
  padding-bottom: @gap*3;
}

.padding-top-x4 {
  padding-top: @gap*4;
}

.padding-bottom-x4 {
  padding-bottom: @gap*4;
}

.padding-top-x6 {
  padding-top: @gap*6;
}

.padding-bottom-x6 {
  padding-bottom: @gap*6;
}



body[data-status="loaded"] .preloader{

    visibility: hidden !important;
    opacity: 0 !important;

  > div{
    opacity: 0;
  }

}