//============================================================================
//+++++++++++++++++++++++++++ FORMS PARAMETERS +++++++++++++++++++++++++++++++
//============================================================================
@field-font: @fw-reg @paragraph-1/135% @font-primary; // field value font

@field-placeholder-color: @color-b3; // placeholder color
@field-bg-color: rgba(@color-w, 1); // background color
@field-text-color: @color-b1; // field value text color
@field-error-text: @color-error; // field error text color

@field-icns-n: @color-b3; // field icon color
@field-icns-a: @color-hover; // field active icon color

@field-br: @br; // field radius

@field-left-padding: calc(@gap*2); // padding-left

// sizes
@checkbox-w: 18px;
@track-h: 4px;
@form-line-height: normal;



.form-border-style {
  border-width: 1px;
  border-color: rgba(@color-b4, 1);
  border-style: solid;
}

.border-focus-style {
  border-width: 1px;
  border-color: rgba(@color-primary, 1);
  border-style: solid;
  //box-shadow: inset 0 0 0 1px rgba(@color-hover, 1);
}

.border-error-style {
  border-width: 1px;
  border-color: rgba(@color-error, 1);
  border-style: solid;
}




