// ACCORDION BASE
.accordion {
  display: flex;
  flex-direction: column;
  //border: 1px solid red;

  .accordion__item {
    display: flex;
    flex-direction: column;
    //border: 1px solid red;
    position: relative;

    .accordion__item__title {
      //border: 1px solid green;
      display: flex;
      align-items: center;
      align-content: center;
      text-align: left;
      position: relative;
      outline: none;

      &.fixed {
        position: fixed;
        z-index: 500;
        background: @color-w;
        border-radius: 0;
        border-width: 1px 1px 0 1px;
        border-color: @color-primary;
        border-style: solid;
        box-shadow: 0 5px 20px -5px rgba(@color-b1, 0.1);
        transform: translateX(-1px);
      }

      &:hover {
        cursor: pointer;
      }

      &::after {
        content: url('data:image/svg+xml;charset=utf-8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.5 12C18.4583 12.4583 18.2083 12.7083 17.75 12.75H12.75V17.75C12.7083 18.2083 12.4583 18.4583 12 18.5C11.5417 18.4583 11.2917 18.2083 11.25 17.75V12.75H6.25C5.79167 12.7083 5.54167 12.4583 5.5 12C5.54167 11.5417 5.79167 11.2917 6.25 11.25H11.25V6.25C11.2917 5.79167 11.5417 5.54167 12 5.5C12.4583 5.54167 12.7083 5.79167 12.75 6.25V11.25H17.75C18.2083 11.2917 18.4583 11.5417 18.5 12Z" fill="rgba(46, 56, 62, 1)"/></svg>');
        position: absolute;
        right: 10px;
        top: 10px;
        color: @color-primary;
      }
    }
  }

  .accordion__item.active {
    > .accordion__item__title {
      &::after {
        content: url('data:image/svg+xml;charset=utf-8,<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.5 12.8711C18.4583 13.3294 18.2083 13.5794 17.75 13.6211H6.25C5.79167 13.5794 5.54167 13.3294 5.5 12.8711C5.54167 12.4128 5.79167 12.1628 6.25 12.1211H17.75C18.2083 12.1628 18.4583 12.4128 18.5 12.8711Z" fill="rgb(46, 56, 62)"/></svg>');
      }
    }

    > .accordion__item__content {
      // display: block;
    }
  }
}

// accordion styles v1
.accordion__theme-one {
  margin-bottom: calc(@gap*2);

  .accordion__item {
    background: @color-w;
    border-radius: @br;
    margin-bottom: @gap;
    border: 1px solid @color-b5;
    box-shadow: @shadow-light;
    .anim_on(@anim);

    &.active {
      border-color: @color-primary;
      // box-shadow: 0 4px 17px 0 rgba(0,76,175, .13), inset 0 0 0 1px @color_1;

      @media (hover: hover) and (pointer: fine) {
        &:hover{
          border-color: @color-hover;

          .accordion__item__title{
            &::after{
              opacity: 1;
            }
          }

        }
      }
    }

    &:not(.active){
      @media (hover: hover) and (pointer: fine) {
          &:hover{
            border-color: @color-hover;

            .accordion__item__title{
              &::after{
                opacity: 1;
              }
            }
          }
      }
    }

    .accordion__item__title {
      //border: 1px solid green;
      width: 100%;
      min-height: 48px;
      padding: calc(@gap*2) 40px calc(@gap*2) calc(@gap*3);

      > * {
        font: @fw-s-bold @headname-5/120% @font-primary !important;
        margin-bottom: 0 !important;

        @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
          font: @fw-s-bold @headname-6/120% @font-primary !important;

        }

      }

      &::after {
        right: calc(@gap*2);
        top: calc(@gap*2);
        opacity: 0.4;
      }
    }

    .accordion__item__content {
      display: none;
      padding: 0 calc(@gap*3) calc(@gap*3) calc(@gap*3);

      > * {
        &:not(:last-child){
          margin-bottom: @gap;
        }

        &:only-child {
          margin-bottom: 0; /* Скидаємо відступ для єдиного елемента */
        }

        font: @fw-reg @paragraph-1/130% @font-primary !important;

        @media only screen and (max-width: @xmedium), only screen and (max-device-width: @xmedium) {
          font: @fw-reg @paragraph-2/130% @font-primary !important;

        }
      }

      //border: 1px solid yellowgreen;
    }


  }

  .accordion__item.active {
    > .accordion__item__title {
      &::after {
        content: url('data:image/svg+xml;charset=utf-8,<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.5 12.8711C18.4583 13.3294 18.2083 13.5794 17.75 13.6211H6.25C5.79167 13.5794 5.54167 13.3294 5.5 12.8711C5.54167 12.4128 5.79167 12.1628 6.25 12.1211H17.75C18.2083 12.1628 18.4583 12.4128 18.5 12.8711Z" fill="rgba(46, 56, 62, 1)"/></svg>');
      }
    }
  }
}


//dropdown-simple
.dropdown-simple {
  &__head {
    padding-right: @gap*4;
    position: relative;
    cursor: pointer;

    &::after {
      content: url('data:image/svg+xml;charset=utf-8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.7148 9.94922L12.4922 14.9531C12.3099 15.099 12.1458 15.1719 12 15.1719C11.8359 15.1719 11.681 15.1081 11.5352 14.9805L6.28516 9.94922C6.15755 9.82161 6.09375 9.66667 6.09375 9.48438C6.09375 9.30208 6.15755 9.14714 6.28516 9.01953C6.57682 8.76432 6.88672 8.76432 7.21484 9.01953L12 13.6133L16.7852 9.01953C17.1133 8.76432 17.4232 8.77344 17.7148 9.04688C17.8424 9.15625 17.9062 9.30208 17.9062 9.48438C17.9062 9.66667 17.8424 9.82161 17.7148 9.94922Z" fill="rgb(156, 163, 174)"/></svg>');
    }


  }

  &__body {
    max-height: 1px;
    transform: scale(1, 0);
    transform-origin: 0 0;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    position: absolute !important;
    transition: all 0.05s linear;
  }

  &.active {
    .dropdown-simple__head {
      &::after {
        content: url('data:image/svg+xml;charset=utf-8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.7148 14.0508L12.4922 9.04688C12.3099 8.90104 12.1458 8.82812 12 8.82812C11.8359 8.82812 11.681 8.89193 11.5352 9.01953L6.28516 14.0508C6.15755 14.1784 6.09375 14.3333 6.09375 14.5156C6.09375 14.6979 6.15755 14.8529 6.28516 14.9805C6.57682 15.2357 6.88672 15.2357 7.21484 14.9805L12 10.3867L16.7852 14.9805C17.1133 15.2357 17.4232 15.2266 17.7148 14.9531C17.8424 14.8438 17.9062 14.6979 17.9062 14.5156C17.9062 14.3333 17.8424 14.1784 17.7148 14.0508Z" fill="rgb(21, 157, 241)"/></svg>');
      }
    }

    .dropdown-simple__body {
      max-height: 400px;
      transform: scale(1, 1);
      position: relative !important;
      opacity: 1;
      pointer-events: all;
      transition: all 0.2s linear;
    }
  }

}


