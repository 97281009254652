// PAGE PARAMETERS
//=============================================================================
html, body{
  min-height: var(--app-height) !important;
  display: flex;
  flex-direction: column;
}

.main-container {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
  max-width: @width-max-limit;
  min-width: @width-min-limit;
  flex: 1 1 auto;
  width: 100%;

  //min-height: var(--app-height);
  margin: auto;
  //overflow: hidden;
  //box-shadow: 0 0 50px 0 rgba(@color-b1, .2);

  > .page-content {
    flex: 1 1 auto;
  }

  > .footer {
    order: 99;
  }
}

.resolution-wrap {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: @width-content-limit;
  height: auto;
  min-height: 100%;
  padding-left: calc(@gap*3);
  padding-right: calc(@gap*3);
}

