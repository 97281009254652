// SELECT TEXT STYLE
//=============================================================================
::selection {
  background: rgba(@color-b1, .8);
  //background: @gradient_1;
  color: @color-w !important;
}

::-moz-selection {
  background: rgba(@color-b1, .8);
  //background: @gradient_1;
  color: @color-w !important;
}

// GRADIENTS
//=============================================================================
.gradient-bg__dark {
  background: @color-b1;
  background: -moz-linear-gradient(31deg, @color-b1 0%, @color-b2 100%);
  background: -webkit-linear-gradient(31deg, @color-b1 0%, @color-b2 100%);
  background: linear-gradient(31deg, @color-b1 0%, @color-b2 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="@{@color-b1}",endColorstr="@{@color-b2}",GradientType=1);
}

// PRIMARY FONTS
//=============================================================================
html, body {
  font: @fw-reg @paragraph-1/140% @font-primary;
  background-size: auto 100%  !important;
  line-height: 130%;
  color: @color-b1;
}


// BOX SHADOW
@shadow-light: 2px 9px 23px -5px rgba(42, 64, 105, .05), 0 2px 4px -2px rgba(0, 0, 0, .05);
@shadow-large: 0 2px 4px -2px rgba(0, 0, 0, .1), 2px 9px 23px -5px rgba(42,64,105, .1);
@primary-btn-hover: 1px 4px 13px -4px rgba(@color-primary, 0.8);


// TEXT ALIGN
.ta_l {
  text-align: left !important;
}

.ta_r {
  text-align: right !important;
}

.ta_c {
  text-align: center !important;
}


// NO SELECT TEXT
.no_select,
.no_select * {
  cursor: default;
  user-select: none !important;
}


// CUSTOM SCROLLBAR
//=============================================================================


@scroll_thumb: @color-b2;
@scroll_thumb_s: rgba(@color-b3, 0);
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: @color-b3;
}

::-webkit-scrollbar-thumb {
  background: @scroll_thumb;
  border-radius: @br;

  &:hover {
    background: @color-primary;
  }

  &:active {
    background: @color-primary;
  }
}


// ANIMATION HOVER LINKS and BUTTONS
//=============================================================================
select,
a, button,
*:before,
*:after,
[type="button"],
[type="submit"],
[type="reset"] {
  .anim_on(@anim);
}

a {
  color: @color-primary;
}

















