:root {
  // App height
  --app-height: 100%;
  --app-width: 100vw;



}


// COLORS
// =======================================================
@color-primary: #159DF1;
@color-hover: #159DF1 + 20%;
@color-secondary: #9EC850;
@color-attention: #F1AE1D;
@color-error: #D84C3A;
@color-b1: #2E383E;
@color-b2: #59616D;
@color-b3: #9CA3AE;
@color-b4: #B5BAC7;
@color-b5: #DBE1E5;
@color-b6: #F0F2F6;
@color-w: #fff;

// TEXT
// =======================================================
@font-primary: 'Roboto', Helvetica, Arial, sans-serif;

// headnames
@headname-1: 50px;
@headname-2: 38px;
@headname-3: 28px;
@headname-4: 22px;
@headname-5: 18px;
@headname-6: 16px;

// paragraph
@paragraph-1: 16px;
@paragraph-2: 14px;
@caption: 12px;
@caption-micro: 10px;

// buttons
@big-btn-text: 16px;
@small-btn-text: 14px;

// menu nav
@header-menu: 16px;

@fw-reg: 400;
@fw-s-bold: 600;
@fw-bold: 700;
@fw-h-bold: 900;

// SIZES
// =======================================================
@header-h: 80px;
@header-small-h: 60px;
@footer-h: 48px;

@sidebar: 240px;


// spaces (gap, padding, margin)
@gap: 8px;

// borders
@br: 8px;

// min-sizes
@elements-min-h: 50px;
@small-elements-min-h: 38px;

// width limit
@width-max-limit: 4200px;
@width-min-limit: 320px;
@width-content-limit: 860px;
@width-text-limit: 680px;
@width-popup-limit: 500px;
@width-field-limit: 448px;

// роздільна здатність
//=============================================================================
@medium: 599.9px;
@xmedium: 767.9px;
@large: 1023.9px;
@xlarge: 1199.9px;
@xxlarge: 1439.9px;

@anim: 0.2s;
.anim_on(@anim) {
  transition: all @anim linear;
}


// шляхи
//=============================================================================
@img: '/img/';
@gallery: '/images/gallery/';