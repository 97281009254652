// ---------------------------------- 404
.section-404 {
  display: flex;
  //min-height: calc(var(--app-height));
  justify-content: center; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios
  flex-direction: column;
  padding: calc(@gap*3) calc(@gap*2) !important;

  > * {
    position: relative;
    z-index: 3;
  }

  &__number {
    font-size: 120px;
    color: @color-primary;
    font-weight: @fw-h-bold !important;
    line-height: 150%;
    letter-spacing: 3px;
    display: flex;
    text-align: center;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios

    img{
      width: 100%;
      max-width: 560px;
    }
  }

  &__details {
    text-align: center;
    max-width: @width-text-limit;
    margin-bottom: calc(@gap*4);
    margin-left: auto;
    margin-right: auto;

    h1{
      font: @fw-s-bold @headname-3/120% @font-primary;
      letter-spacing: 0.05em;
    }
    p{
      font: @fw-reg @paragraph-1/120% @font-primary;
    }
  }

}



